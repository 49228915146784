import classnames from "classnames";
import { useEffect } from "react";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";

interface Iprops extends LinkProps {
    themecolor?: string
    active?: any
}

export default function CustomLink(props: Iprops) {

    const to = props.to;
    const children = props.children;

    const resolved = useResolvedPath(to);
    const match = useMatch({ path: `${resolved?.pathname}/*` });

    useEffect(() => {
        if (props.active) {
            props.active(!!match)
        }
    }, [match])

    return (
        <Link to={to} className={`${classnames({ 'active': !!match })} ${props.className}`} style={{ color: !!match && props.themecolor ? props.themecolor : null }}>
            {children}
        </Link>
    )
}