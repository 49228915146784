import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";
import { BasicControl, TnEngineContext, TnEngineExtraContext, ViewEditor, Listeners } from "pytha";


export class AwakeCaptureModalControl extends BasicControl {

    isSingleUse = true;

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;
    
    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.extraContext.dymodeContext.reset();
        listeners.getSignal(EXTRA_SINGAL.onCaptureSettingAwake).dispatch();
        editor.listeners.signals.onDymodeVisible.dispatch(false);
    }
    dispose(): void {

    }

}