import { Button, Select, Tooltip } from 'antd'
import { useState } from 'react'
import './UcsSelect.less'
import { ReactComponent as AxisSvg } from "@/commons/icons/ucs/axis.svg";
import { ReactComponent as WordSvg } from "@/commons/icons/ucs/world.svg";

import UcsManage from './UcsManage'
import { AxisT, ToggleUcsCommand } from 'pytha';
import { graphicStore } from '@/commons/store/graphic-store';
import { DragModal } from 'tncet-common';

const { Option } = Select;

export default function UcsSelect() {

    const [ucsModalVisible, setucsModalVisible] = useState<boolean>(false)

    const onChange = (uuid: string) => {
        let ucs = graphicStore?.context?.ucsContext.ucsList.find(item => item.uuid === uuid);
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(ucs));
    }

    return (
        <div className="ucs-select">
            <Tooltip title="用户坐标" color="#555555">
                <Button type="link" size="small" onClick={() => setucsModalVisible(true)}>UCS+</Button>
            </Tooltip>
            <Select
                style={{ width: '130px' }}
                size="small"
                value={graphicStore?.context?.ucsContext.ucsUuid}
                onChange={onChange}>
                {graphicStore?.context?.ucsContext.ucsList.map((ucs: AxisT, key) => (
                    <Option value={ucs.uuid} key={key}>
                        {
                            <div className="ucs-select-option">
                                <div className="ucs-select-icon">
                                    {ucs.isWcs ? <WordSvg style={{ width: "14px", height: "14px" }} /> : <AxisSvg style={{ width: "14px", height: "14px" }} />}
                                </div>
                                <div className="ucs-select-name">{ucs.name}</div>
                            </div>
                        }
                    </Option>
                ))}
            </Select>

            <DragModal
                visible={ucsModalVisible}
                destroyOnClose
                centered={false}
                width={400}
                onClose={() => { setucsModalVisible(false) }}
                title='UCS'>
                <UcsManage></UcsManage>
            </DragModal>
        </div>
    )
}
