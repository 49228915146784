import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";
import { BasicControl, TnEngineContext, TnEngineExtraContext, ViewEditor, Listeners } from "pytha";


export class AwakeLayerModalControl extends BasicControl {

    isSingleUse = true;

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.extraContext.dymodeContext.reset();
        editor.listeners.signals.onDymodeVisible.dispatch(false);
        this.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake).dispatch();
    }
    dispose(): void {

    }

}