import { Button, Form, FormInstance, Input, InputRef, message, Popconfirm, Space } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react'
import SidebarItem from './sidebar-item';
import './sidebar.less'
import {
    PlusOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '@/App';
import classnames from 'classnames';
import { Project } from '@/commons/interface/project';
import { NavigationStatus } from '@/commons/context/navigation-context';
import { userCheckLogin } from '@/api/spec-common/user';
import { User } from '@/commons/interface/user';
import { addProject, deleteProject, findProjects, updateProject } from '@/api/spec-common/project';
import { ContextMenuFC, DragModal } from 'tncet-common';

export interface IsidebarItem extends Project {
    icon?: any,
    expanded?: boolean,
    children?: IsidebarItem[],
    url?: string,
    key?: number,
    themeColor?: string,
    hideArrow?: boolean
    count?: number,
    active?: boolean,
}

interface Iprops {
    onProjectCreate?: (value: number) => void
}
export default function GlobalSidebar(props: Iprops) {

    const [routes, setRoutes] = useState<IsidebarItem[]>([]);
    const [addProjectVisible, setAddProjectVisible] = useState<boolean>(false);
    const [renameProjectVisible, setRenameProjectVisible] = useState<boolean>(false);
    const [addLoading, setAddLoading] = useState<boolean>(false);
    const [renameLoading, setRenameLoading] = useState<boolean>(false);
    // 激活的项目id
    let [activedRouteId, setActivedRouteId] = useState<number>();
    const ref = useRef<InputRef>(null);
    const navigate = useNavigate();
    const navigationContext = useContext<NavigationStatus>(NavigationContext);
    const renameRef = useRef<FormInstance>();

    // 右键菜单
    let [cmVisible, setCmVisible] = useState(false);

    let [contextMenuLeft, setContextMenuLeft] = useState<number>();
    let [contextMenuTop, setContextMenuTop] = useState<number>();
    const [selectProject, setSelectProject] = useState<IsidebarItem>();

    const [isDemoUser, setIsDemoUser] = useState<boolean>();
    useEffect(() => {
        userCheckLogin()
            .then(res => {
                let userDetail: User = res.data;
                setIsDemoUser(userDetail.type === 3);
            })
    }, [])

    useEffect(() => {
        generateData(true);
    }, [])

    const generateData = (shoudNavigate: boolean = false) => {
        let isDemo = false;
        userCheckLogin()
            .then(res => {
                let userDetail: User = res.data;
                isDemo = userDetail.type === 3;
                setIsDemoUser(isDemo);

                return findProjects();
            })
            .then(res => {
                let ps: Project[] = res.data || [];
                if (isDemo) {
                    ps = ps.filter(item => item.isDemo);
                }
                let routes = ps.map(item => {
                    return {
                        url: `/desktop/${item.id}`,
                        name: item.name,
                        id: item.id
                    }
                });
                setRoutes(routes);

                if (shoudNavigate && routes.length > 0) {
                    let currentProjectId = navigationContext.currentProjectId;
                    let route = routes[0];
                    if (currentProjectId != null) {
                        let r = routes.find(item => item.id === currentProjectId);
                        if (r != null) {
                            route = r;
                        }
                    }
                    navigate(route.url);
                } else if (shoudNavigate) {
                    navigate(`/desktop`);
                }
            })
    }

    const focusInput = () => {
        setTimeout(() => {
            ref.current?.focus({
                cursor: 'end'
            })
        }, 1)
    }

    const onCreateProject = (values) => {
        let data = {
            name: values['name'],
            isDemo: isDemoUser,
        }
        setAddLoading(true);
        addProject(data).then(res => {
            message.success('创建成功');
            setAddProjectVisible(false);
            navigationContext.setCurrentProjectId(res.data);
            generateData(true);
            // navigate(`/desktop/${res.data}`)
        }).finally(() => {
            setAddLoading(false);
        })
    }

    const onRename = (values) => {
        if (selectProject == null) {
            setRenameProjectVisible(false);
            return;
        }
        let data = {
            name: values['name']
        }
        setRenameLoading(true)
        updateProject(selectProject.id, data).then(res => {
            generateData();
            setRenameProjectVisible(false);
            setSelectProject(null)
        }).finally(() => {
            setRenameLoading(false)
        })
    }

    const onDelete = (value: IsidebarItem) => {
        if (value == null) {
            setCmVisible(false)
            return;
        }
        let projectId = value.id;

        deleteProject(projectId).then(res => {
            if (activedRouteId === projectId) {
                navigationContext.setCurrentProjectId(null);
                generateData(true);
            } else {
                generateData();
            }
        }).finally(() => {
            setCmVisible(false)
        })
    }

    return (
        <div className="sidebar-container">
            <div className="logo">
                <img src="/assets/logo-color.png" alt="tncet" height="40px" />
            </div>
            <div className='btn-group'>
                <Button icon={<PlusOutlined />} type="primary" block onClick={e => {
                    setAddProjectVisible(true);
                    focusInput();
                }}>
                    新建项目
                </Button>
                {/* <Button icon={<PlusOutlined />} type="primary" block>
                    新建图纸
                </Button>
                <Button icon={<UploadOutlined />} block>
                    导入本地文件
                </Button> */}

            </div>
            <div className='scrollable'>
                {routes.map(item => (
                    <SidebarItem
                        route={item}
                        key={item.id}
                        activedRouteId={activedRouteId}
                        toggleActiveRoute={(id) => {
                            navigationContext.setCurrentProjectId(id);
                            setActivedRouteId(id)
                        }}
                        onContextMenu={e => {
                            e.preventDefault();
                            let clientX = e.clientX;
                            let clientY = e.clientY;
                            setContextMenuLeft(clientX);
                            setContextMenuTop(clientY);
                            setSelectProject(item)
                            setCmVisible(true);
                        }}
                        selected={selectProject?.id === item.id}
                    />
                ))}
            </div>

            <DragModal
                visible={addProjectVisible}
                title="添加项目"
                onClose={() => setAddProjectVisible(false)}
                destroyOnClose
                // onFocus={() => { inputRef.current && inputRef.current!.focus({ cursor: 'end', }) }}
                width={400}
                centered={false}
                mask
                style={{ top: '40px' }}
            >
                <>
                    <Form size='small' autoComplete="off" onFinish={onCreateProject} className="add-project-modal">
                        <Form.Item name="name"
                            rules={[{ required: true, message: '名称不能为空' },
                            // { pattern: /^[^\:\*\?\"\/\\\<\>\|]*$/, message: '文件名不能包含下列任何字符：\\/:*?"<>|' },
                            { max: 200 }]}
                        >
                            <Input placeholder="请输入项目名称" ref={ref} />
                        </Form.Item>
                        <div className='footer'>
                            <Space>
                                <Button size="small" type="primary" htmlType="submit" loading={addLoading}>保存</Button>
                                <Button size="small" onClick={() => setAddProjectVisible(false)}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </>
            </DragModal>
            <DragModal
                visible={renameProjectVisible}
                title="重命名"
                onClose={() => {
                    setRenameProjectVisible(false)
                    setSelectProject(null);
                }}
                destroyOnClose
                width={400}
                centered={false}
                mask
                style={{
                    top: '40px'
                }}
            >
                <>
                    <Form ref={renameRef} autoComplete="off" onFinish={onRename} className="rename-project-modal">
                        <Form.Item name="name" initialValue={selectProject?.name} rules={[{ required: true, message: '名称不能为空' }, { max: 200 }]}>
                            <Input ref={ref} id='renameInput' placeholder="请输入项目名称" autoFocus />
                        </Form.Item>
                        <div className='footer'>
                            <Space>
                                <div>{`${selectProject?.name || ''}`}</div>
                                <Button size="small" type="primary" htmlType="submit" loading={renameLoading}>保存</Button>
                                <Button size="small" onClick={() => {
                                    setRenameProjectVisible(false)
                                    setSelectProject(null);
                                }}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </>
            </DragModal>
            <ContextMenuFC
                visible={cmVisible}
                left={contextMenuLeft}
                top={contextMenuTop}
                width={140}
                onCancel={() => {
                    setCmVisible(false);
                    setSelectProject(null)
                }}
                wrapperClassname='cm-container'
            >
                <div className='cm-body'>
                    <div className={classnames({
                        'option': true,
                        'disabled': false
                    })} onClick={() => {
                        setCmVisible(false);
                        setRenameProjectVisible(true);
                        focusInput();
                    }}>重命名</div>
                    <Popconfirm
                        title="确认删除"
                        onCancel={() => {
                            setSelectProject(null);
                            setCmVisible(false);
                        }}
                        onConfirm={() => onDelete(selectProject)}>
                        <div className={classnames({
                            'option': true,
                            'disabled': false
                        })}>删除项目</div>
                    </Popconfirm>


                </div>
            </ContextMenuFC>
        </div>
    )
}