
import { User } from "@/commons/interface/user";
import { StdResponse, graphicAxios } from "@/config/axios"
import { AxiosResponse } from "axios";

export function getUsers(params?: {}): Promise<AxiosResponse<any>> {
    //params可选参数:  
    //    input: string
    //    projectId: number
    //    excludeProjectId: number   返回除了该项目外的所有用户，用在为项目添加用户的时候
    return graphicAxios.get(`/users`, {
        params: {
            ...params,
        }
    })
}

export function addUser(user: User, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/users`, user, {
        params: {
            ...params,
        }
    })
}

export function updateUser(userId: number, user: User, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.put(`/users/${userId}`, user, {
        params: {
            ...params,
        }
    })
}


export function deleteUser(userId: number, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.delete(`/users/${userId}`, {
        params: {
            ...params,
        }
    })
}

export function modifyUserPsd(userId: number, json: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/users/${userId}/password`, json, {
        params: {
            ...params,
        }
    })
}

export function userCheckLogin(params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.get(`/users/check-login`, {
        params: params
    })
}

export function userLogin(user: User, params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.post(`/users/login`, user, {
        params: params
    })
}

export function getUser(userId: number, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/users/${userId}`, {
        params: {
            params,
        }
    })
}

//发送验证码
export function sendCodeSmsMsg(mobile: string): Promise<StdResponse<any>> {
    return graphicAxios.post(`/users/code`, null, {
        params: {
            mobile: mobile,
        }
    })
}
//找回密码
export function forgetUserPasswordSubmit(data: any, params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.post(`/users/password/forget`, data, {
        params: params
    })
}

// 验证密码是否正确
export function getUsersCheckPassword(params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.get(`/users/password/check`, {
        params: params
    })
}
