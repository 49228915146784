import { TnEngineExtraContext } from "pytha";
import {
    createPrimitive,
    createPrimitiveMulti,
    deletePrimitivesInGraphic,
    findPrimitivesInGraphic,
} from "@/api/geometry/primitive";
import { findFontGlyphs, findFontTtfs, findFontsInGraphic, saveFont } from "@/api/geometry/font";
import { deleteUcs, saveUcs } from "@/api/geometry/ucs";
import { createLayer, deleteLayer, updateLayer } from "@/api/geometry/layer";

export function cmdRegister() {
    let extraContext = TnEngineExtraContext.getInstance();

    extraContext.apiContext.createPrimitive = createPrimitive;
    extraContext.apiContext.createPrimitiveMulti = createPrimitiveMulti;
    extraContext.apiContext.deletePrimitivesInGraphic = deletePrimitivesInGraphic;
    extraContext.apiContext.findPrimitivesInGraphic = findPrimitivesInGraphic;
    extraContext.apiContext.findOneTypePrimitivesInGraphic = findPrimitivesInGraphic;

    extraContext.apiContext.saveUcs = saveUcs;
    extraContext.apiContext.deleteUcs = deleteUcs;

    extraContext.apiContext.updateLayer = updateLayer;
    extraContext.apiContext.createLayer = createLayer;
    extraContext.apiContext.deleteLayer = deleteLayer;

    extraContext.apiContext.findFontGlyphs = findFontGlyphs;
    extraContext.apiContext.findFontsInGraphic = findFontsInGraphic;
    extraContext.apiContext.findFontTtfs = findFontTtfs;

    extraContext.apiContext.saveFont = saveFont;

}