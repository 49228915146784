import { useEffect, useRef, useState } from "react";
import './index.less'
import GlobalSidebar from "./sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Popconfirm, Popover, Space, Table } from "antd";
import {
    LogoutOutlined,
    PlusOutlined,
    UploadOutlined,
    EllipsisOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import dayjs from "dayjs";
import classnames from "classnames";
import { graphicProject } from "@/commons/interface/graphicProject";
import { Project } from "@/commons/interface/project";
import { findProjectDetail } from "@/api/spec-common/project";
import { copyGraphicProject, deleteGraphicProjectByUuid, findGraphicProjectsByProjectId, saveGraphicProject } from "@/api/spec-common/graphicProject";
import { ModelUtils } from "pytha";
import localStore from "@/commons/store/local-store";
import { ContextMenuFC } from "tncet-common";


export default function GlobalLayout() {
    const { projectId } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<graphicProject>();
    const [files, setFiles] = useState<graphicProject[]>([]);
    const [displayFiles, setDisplayFiles] = useState<graphicProject[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [filename, setFilename] = useState<string>('');
    const navigate = useNavigate();

    const [project, setProject] = useState<Project>();

    const ref = useRef<InputRef>(null)
    // 右键菜单
    let [cmVisible, setCmVisible] = useState(false);
    let [contextMenuLeft, setContextMenuLeft] = useState<number>();
    let [contextMenuTop, setContextMenuTop] = useState<number>();
    const [selectProject, setSelectProject] = useState<graphicProject>();



    useEffect(() => {
        if (isNaN(+projectId)) {
            setFiles([]);
            setDisplayFiles([]);
            return;
        }
        generateData(+projectId);
    }, [projectId])

    const generateData = (projectId) => {
        setLoading(true)
        findProjectDetail(projectId).then(res => {
            setProject(res.data)
            return findGraphicProjectsByProjectId(projectId);
        })
        .then(res => {
            let files: graphicProject[] = res.data || [];
            files = files.sort((a, b) => b.lastModifiedTime - a.lastModifiedTime)
            setFiles(files);
            handleSearchChange(searchValue, files);
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSearchChange = (value, files: graphicProject[]) => {
        if (!value) {
            setDisplayFiles(files)
        }
        let displayFiles = files.filter(item => item.name.match(value)) || [];
        setDisplayFiles(displayFiles);
    }

    const create = () => {
        if (isNaN(+projectId)) return;
        let uuid = ModelUtils.generateUUID();
        // let uuid = "3d53c876-4bd3-4d86-ae05-2967f8fc4b9d";
        let newFile = {
            uuid: uuid,
            name: `新项目${uuid.slice(0, 6)}`,
            lastModifiedTime: new Date(),
            projectId: +projectId,
            isDemo: !!project?.isDemo
        };
        setLoading(true);
        saveGraphicProject(newFile).then(res => {
            navigate(`/graphic/${uuid}`)
        }).finally(() => {
            setLoading(false);
        })
    }

    const changeName = (record: graphicProject, value: string) => {
        record.renaming = false;
        record.name = value;
        saveGraphicProject(record).then(res => {
            setDisplayFiles([...displayFiles]);
        })
    }

    const onGraphicClick = (record) => {
        localStore.graphicName = record.name;
        let data = {
            ...record,
            lastModifiedTime: new Date(),
        }
        saveGraphicProject(data).then(res => { })
        navigate(`/graphic/${record.uuid}`);
    }

    const onDelete = (record: graphicProject) => {
        // fileStore.deleteFile(record);
        // record.has_deleted = true;
        // record.delete_time = new Date();
        // saveGraphicProject(record).then(res => {
        //     this.setState({
        //         files: this.state.files.filter(item => item.uuid != record.uuid),
        //         displayFiles: this.state.displayFiles.filter(item => item.uuid != record.uuid)
        //     })
        // })
        setMenuVisible(false);
        setLoading(true);
        deleteGraphicProjectByUuid(record.uuid).then(res => {
            let filterFiles = files.filter(item => item.uuid != record.uuid)
            setFiles(filterFiles);
            handleSearchChange(searchValue, filterFiles);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onFileImport = () => {
        if (isNaN(+projectId)) return;
        let input = document.getElementById('dxf-global');
        input.click();
    }

    const onFileSelected = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        if (file == null) return;
        setFilename(file.name)
        reader.onloadend = fileLoad;
        reader.readAsText(file);
        // let readerAddress = new FileReader();
        // readerAddress.readAsDataURL(file);
        // readerAddress.onloadend = this.fileAddress;
    }

    const fileLoad = (event) => {
        let uuid = ModelUtils.generateUUID();
        let newFile = {
            uuid: uuid,
            name: filename,
            projectId: +projectId
        };
        saveGraphicProject(newFile).then(res => {
            let fileReader = event.target;
            if (fileReader.error) return console.log("error onloadend!");

            //TODO dxf解析

            // let parser = new DxfParser();
            // let dxf = parser.parseSync(fileReader.result);
            // systemStore.dxfFile = dxf;
            // systemStore.dxfFileName = filename;
            // let dxfToGraphic = new DxfToEntityUtil();
            // dxfToGraphic.Viewer(dxf, newFile);
            navigate(`/graphic/${uuid}`)
        })
    }

    const logOut = () => {
        // cookieStore.clear();
        localStore.clear();
        navigate('/login');
    }


    const menu = (
        <div className="menu">
            <div className="menu-item" onClick={(e) => {
                onDelete(selected)
            }}>删除</div>
            <div className="menu-item" onClick={(e) => {
                selected.renaming = true;
                setMenuVisible(false);
                setTimeout(() => {
                    ref.current?.focus({
                        cursor: 'all',
                    })
                }, 1)
            }}>重命名</div>
            <div className="menu-item" onClick={(e) => {
                onCopy(selected)
            }}>复制项目</div>        
        </div>
    )

    const onCopy = (project: graphicProject) => {
        if (isNaN(+projectId)) return;
        let uuid = ModelUtils.generateUUID();
        let newFile = {
            uuid: uuid,
            name: `新项目${uuid.slice(0, 6)}`,
            lastModifiedTime: new Date(),
            projectId: +projectId
        };
        setLoading(true);
        copyGraphicProject(newFile, { oldGraphictUuid: project.uuid }).then(res => {
            navigate(`/graphic/${uuid}`)
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="global-content">
            <div className="global-content-view">
                <GlobalSidebar />
                <div className="global-page-route">
                    <div className="top-tools">
                        <Space>
                            <Button icon={<PlusOutlined />} danger onClick={create} loading={loading} disabled={isNaN(+projectId)}>
                                新建图纸
                            </Button>
                            <div className="file-import-global">
                                <Button block className="file-btn" onClick={onFileImport} disabled={isNaN(+projectId)}><UploadOutlined />导入本地文件</Button>
                                <input type="file" accept=".dxf" id="dxf-global" onChange={onFileSelected} style={{ display: 'none' }} />
                            </div>
                            <Input
                                placeholder="搜索"
                                prefix={<SearchOutlined />}
                                className="search-input"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onPressEnter={() => handleSearchChange(searchValue, files)}
                                onBlur={() => handleSearchChange(searchValue, files)} />
                            <div className="right">
                            <div className="action logout-btn" style={{"position":"absolute", "right":"5px"}} onClick={logOut}>
                                <LogoutOutlined />
                                <span className="content">退出登录</span>
                            </div>
                        </div>
                        </Space>

                    </div>
                    <div className="main-content">
                        <Table
                            rowKey="uuid"
                            dataSource={displayFiles}
                            pagination={false}
                            loading={loading}
                            rowClassName="row"
                            onRow={(record) => {
                                return {
                                    onContextMenu: e => {
                                        e.preventDefault();
                                        let clientX = e.clientX;
                                        let clientY = e.clientY;
                                        setContextMenuLeft(clientX);
                                        setContextMenuTop(clientY);
                                        setSelectProject(record);
                                        setCmVisible(true);
                                    }
                                }
                            }}>
                            <Table.Column title="名称" ellipsis render={record => {
                                return (
                                    <div className="file-item">
                                        <div className="name">
                                            {(!!record.renaming) ? (
                                                <Input
                                                    className="input"
                                                    ref={record.uuid === selected.uuid ? ref : null}
                                                    size="small"
                                                    bordered={false}
                                                    style={{ backgroundColor: '#fff' }}
                                                    defaultValue={record.name}
                                                    onBlur={(e) => changeName(record, e.target.value)}
                                                    //@ts-ignore
                                                    onPressEnter={(e) => changeName(record, e.target.value)} />
                                            ) : (
                                                <div style={{ cursor: 'pointer' }} onClick={e => onGraphicClick(record)}>
                                                    {record.name}
                                                </div>
                                            )}
                                        </div>
                                        <div className="fill-remaining-space"></div>
                                        <div className={classnames({
                                            "extra-action": true,
                                        })}>
                                            <Popover
                                                trigger="click"
                                                placement="bottomLeft" content={menu}
                                                open={menuVisible && record.uuid === selected.uuid}
                                                onOpenChange={(e) => {
                                                    setMenuVisible(e);
                                                }}
                                            >
                                                <div className="menu-btn" onClick={e => {
                                                    setSelected(record)
                                                }}>
                                                    <EllipsisOutlined />
                                                </div>
                                            </Popover>
                                        </div>
                                    </div>
                                )
                            }} />
                            <Table.Column title="创建人" width='25%' render={record => (
                                <div>
                                    {record.create_user_id}
                                </div>
                            )} />
                            <Table.Column title="最近查看" width='20%'
                                sorter={(a: graphicProject, b: graphicProject) => {
                                    return a.lastModifiedTime > b.lastModifiedTime ? 1 : -1;
                                }}
                                render={record => (
                                    <div>
                                        {record.lastModifiedTime ? dayjs(record.lastModifiedTime).format('YYYY-MM-DD HH:mm') : null}
                                    </div>
                                )} />
                        </Table>
                    </div>
                </div>
            </div>
            <ContextMenuFC
                visible={cmVisible}
                left={contextMenuLeft}
                top={contextMenuTop}
                width={140}
                onCancel={() => {
                    setCmVisible(false);
                    setSelectProject(null);
                }}
                wrapperClassname='cm-container'
            >
                <div className='cm-body'>
                    <Popconfirm
                        title="确认复制"
                        onCancel={() => {
                            setSelectProject(null);
                            setCmVisible(false);
                        }}
                        onConfirm={() => onCopy(selectProject)}>
                        <div className={classnames({
                            'option': true,
                            'disabled': false
                        })}>复制项目</div>
                    </Popconfirm>
                </div>
            </ContextMenuFC>
        </div>
    )
}