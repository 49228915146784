import { Alert, Button, Checkbox, Form, Input } from 'antd'
import { useEffect, useState } from "react";
import { UserOutlined, LockOutlined, CopyrightCircleOutlined } from '@ant-design/icons'
import './login.less'
import { NavLink, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import localStore, { REMEMBER_USERNAME, REMEMBER_TAG, REMEMBER_PASSWORD, USER_UUID, AUTHORIZATION } from '@/commons/store/local-store';
import { userCheckLogin, userLogin } from '@/api/spec-common/user';
import { User } from '@/commons/interface/user';

export default function Login() {
    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        // 从本地存储中获取自动保存的账户信息
        let username = localStore.get(REMEMBER_USERNAME);
        let remember = localStore.get(REMEMBER_TAG);
        let password = localStore.get(REMEMBER_PASSWORD) || '';
        form.setFieldsValue({
            username: username,
            remember: remember,
            password: CryptoJS.enc.Base64.parse(password).toString(CryptoJS.enc.Utf8),
        });
    }, [])

    useEffect(() => {
        // checkout login
        if (localStore.token == null) {
            localStore.clear();
            return;
        }
        checkLogin();
    },[])

    const checkLogin = () => {
        userCheckLogin()
          .then(res => {
            let userDetail: User = res.data;
            // cookieStore.setLoginId(userDetail.id);
            // cookieStore.setUUID(userDetail.uuid);
            // cookieStore.setUser(userDetail);
            localStore.set(USER_UUID, userDetail.id);
            localStore.userId = userDetail.uuid;
            navigate('/desktop');
          }).catch(err => {
            localStore.clear();
            // navigate('/login')
          })
      }


    const login = (values) => {
        //错误信息清空
        setErrorMessage('');
        setLoading(true);

        let data: User = {
            username: values.username,
            password: CryptoJS.MD5(values.password).toString()
        }
        userLogin(data).then(res => {
            let userDetail: User = res.data;
            // 登录失败
            if (res.status !== 200) {
                setErrorMessage(res?.message);
                setRefresh(!refresh);
                return;
            }
            // cookieStore.setToken(userDetail.token);
            // cookieStore.setLoginId(userDetail.id);
            // cookieStore.setUUID(userDetail.uuid);
            // cookieStore.setUser(userDetail);
            localStore.set(AUTHORIZATION, userDetail.token);
            localStore.set(USER_UUID, userDetail.id);
            localStore.userId = userDetail.uuid;
            if (values.remember) {
                localStore.set(REMEMBER_USERNAME, values.username);
                localStore.set(REMEMBER_PASSWORD, CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(values.password)));
                localStore.set(REMEMBER_TAG, values.remember);
            } else {
                localStore.remove(REMEMBER_USERNAME);
                localStore.remove(REMEMBER_PASSWORD);
                localStore.remove(REMEMBER_TAG);
            }
            navigate('/desktop');
            /*    let userType = userDetail.type;
               if (userType === USER_TYPE.ROOT) {
                   navigate('/admin');
               } else {
                   navigate('/main');
               } */
        }).catch(err => {
            console.log(err);
            setErrorMessage('登录失败')
            setRefresh(!refresh);
        }).finally(() => {
            setLoading(false);
        })

    }

    return (
        <div className="login-container">
            <div className="title">
                Pythagras
            </div>
            <div className="content">
                <Form className="login-form" form={form} onFinish={login} autoComplete="off">
                    {!!errorMessage && (
                        <Alert
                            style={{ marginBottom: '10px' }}
                            message={errorMessage}
                            type="error"
                            closable
                            onClose={() => setErrorMessage('')} />
                    )}

                    <Form.Item name="username" rules={[{ required: true, message: '用户名不能为空' }]}>
                        <Input prefix={<UserOutlined />} placeholder="用户名" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                        <Input.Password prefix={<LockOutlined />} placeholder="密码" />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>记住我</Checkbox>
                        </Form.Item>
                        <NavLink className="login-form-forgot" to={"/forget"}>
                            忘记密码?
                        </NavLink>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            登 录
                        </Button>

                    </Form.Item>
                </Form>
            </div>
            <div className="footer">
                <div className="copyright">
                    Copyright <CopyrightCircleOutlined /> 2023
                    <a href="http://www.tncet.com" target="_blank">同恩工程技术</a>
                </div>
            </div>
        </div>
    )
}
