import { Button, Card, Input, message, Select, Space } from 'antd';
import { useEffect, useRef, useState } from 'react'
import './TextStyleManage.less';
import classnames from 'classnames';
import { AmbientLight, Color, Mesh, Object3D, OrthographicCamera, Scene, Vector3, WebGLRenderer } from 'three';
import { Font, ttf } from '@/commons/interface/font';
import { graphicStore } from '@/commons/store/graphic-store';
import { findFontGlyphs } from '@/api/geometry/font';
import { DTextT, RemoveTextStyleCommand, ToggleTextStyleCommand, UpdateTextStylePropertyCommand } from 'pytha';
import { EXTRA_SINGAL } from '@/tool/enums/extra-singal';

const { Option } = Select;

interface IProps {
    onCancel?: () => void,
}

export default function TextStyleManage(props: IProps) {
    const ref = useRef<any>();
    const factorInputRef = useRef<any>();
    const renameRef = useRef<any>();
    const renderer = useRef<WebGLRenderer>();
    const camera = useRef<OrthographicCamera>();
    const scene = useRef<Object3D>();
    const dtext = useRef<DTextT>();
    const text = useRef<Mesh>();
    const ttf = useRef<ttf>();
    // const Font: any = null();
    const demo: string = "AaBbCc"

    const [selectedFont, setSelectedFont] = useState<Font>(graphicStore?.extraContext?.fontContext?.state?.fontList[0] || null)
    const [selectFontName, setSelectFontName] = useState<string>(graphicStore?.extraContext?.fontContext.state.currentFont.fontname)
    const [selectStyle, setSelectStyle] = useState<string>(graphicStore?.extraContext?.fontContext.state.currentFont.style)
    const [selectFactor, setSelectFactor] = useState<any>(graphicStore?.extraContext?.fontContext.state.currentFont.factor)
    const [renamingName, setRenamingName] = useState<string>(null)
    const [applyLoading, setApplyLoading] = useState<boolean>(false)
    const [applyDisable, setApplyDisable] = useState<boolean>(false)

    useEffect(() => {
        checkApplyDisable()
    }, [selectStyle, selectFontName, selectFactor])

    useEffect(() => {
        let font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;
        if (!!font) {
            let midTtf = graphicStore?.extraContext?.fontContext.ttfList.find(item => item.name === font.fontname && item.style === font.style);
            ttf.current = midTtf
        }
        createRender();
        createCamera();
        createScene();
    }, [])

    const createRender = () => {
        const show = document.getElementById('textStyle-show-panel');

        const showRenderer = new WebGLRenderer();
        showRenderer.setClearColor('#000000', 1.0);
        showRenderer.autoClear = false;
        showRenderer.setSize(200, 100);
        show.appendChild(showRenderer.domElement);
        renderer.current = showRenderer;
    }

    const createCamera = () => {
        const midCamera = new OrthographicCamera(-10, 10, 5, -5, 0, 10);
        midCamera.position.set(0, 0, 5);
        midCamera.lookAt(0, 0, 0);
        camera.current = midCamera;
    }

    const createScene = () => {
        let midScene = new Scene();
        midScene.background = new Color(0, 0, 0)
        const ambient = new AmbientLight(new Color(1, 1, 1));
        midScene.add(ambient);
        let font: Font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;
        let midttf = ttf.current;
        if (!midttf) {
            midttf = graphicStore?.extraContext?.fontContext.ttfList.find(item => item.name === font.fontname && item.style === font.style);
            // setTtf(midttf)
            ttf.current = midttf
        }

        let params = { ...graphicStore?.extraContext?.fontContext.findCurrentTtf(midttf), text: demo };
        findFontGlyphs(params).then(res => {
            // console.log("Res:", res);

            // todo: 获取字符失败！
            let midDtext = new DTextT(demo, new Vector3(-8, -2, 0), res.data, 3.5);
            let midText = midDtext.render();
            // console.log("midText", midText);

            midScene.add(midText);
            dtext.current = midDtext
            text.current = midText
            scene.current = midScene
            renderer.current.render(scene.current, camera.current);
        });
    }

    const updateTextExample = () => {
        setTimeout(() => {
            let params = { ...graphicStore?.extraContext?.fontContext.findCurrentTtf(ttf.current), text: demo };
            findFontGlyphs(params).then(res => {
                dtext.current.fontData = res.data;
                dtext.current.needUpdate = true;
                text.current = dtext.current.updateViewGeometry();
                renderer.current.render(scene.current, camera.current);
            });
        }, 0);
    }

    const onChangeSelectedFont = (font: Font) => {
        setSelectFontName(font.fontname)
        setSelectStyle(font.style)
        setSelectFactor(font.factor)
        setSelectedFont(font)

        ttf.current = graphicStore?.extraContext?.fontContext.ttfList.find(item => item.name === font.fontname && item.style === font.style);
        updateTextExample();
    }

    const onChangeFontName = (fontname: string) => {
        const ttfName = graphicStore?.extraContext?.fontContext.state.ttfNameList.find(item => item.name === fontname);
        let style = ttfName.style[0] || '';
        setSelectFontName(fontname)
        setSelectStyle(style)
        ttf.current = graphicStore?.extraContext?.fontContext.ttfList.find(item => item.name === fontname && item.style === style);
        updateTextExample();
    }

    const onChangeFontStyle = (style: string) => {
        setSelectStyle(style)
        ttf.current = graphicStore?.extraContext?.fontContext.ttfList.find(item => item.name === selectFontName && item.style === style);
        updateTextExample();
    }

    const onCangeFactor = () => {
        let reg: RegExp = /^(\d+)\.?\d*/;

        let res = reg.test(selectFactor || '');

        if (res) {
            dtext.current.font.factor = +selectFactor;
            updateTextExample();
        } else {
            message.info("格式不符合要求！");
        }
    }

    const setCurrentFont = () => {
        let font = selectedFont;
        if (font == null) return;
        graphicStore?.extraContext.getCurrentViewEditor().history.execute(new ToggleTextStyleCommand(graphicStore?.extraContext?.fontContext.state.currentFont, font));
    }

    const ondelete = () => {
        const font: Font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;
        if (font == null) {
            message.error('当前选择文字样式为空');
            return;
        }
        if (font.uuid === graphicStore?.extraContext?.fontContext.state.currentFont.uuid) {
            message.error('当前使用样式无法删除');
            return;
        }
        if (!!font.originFont) {
            message.error('standard样式无法删除');
            return;
        }
        for (let key of Object.keys(graphicStore?.extraContext?.getCurrentViewEditor().entityDict)) {
            let entity = graphicStore?.extraContext?.getCurrentViewEditor().entityDict[key];
            if (entity.type === 'DTextT') {
                let dtext = entity as DTextT;
                if (dtext.fontUuid === font.uuid) {
                    message.error('不能删除正在使用的样式');
                    return;
                }
            }
        }
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new RemoveTextStyleCommand(font));
    }

    const activeFontRenaming = () => {
        const font: Font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;
        if (font == null) return;
        if (font.originFont) {
            message.error('Standard样式无法重命名');
            return;
        }
        setRenamingName(font.name)
        setTimeout(() => {
            renameRef.current?.focus({ cursor: 'all' })
        }, 0);
    }

    const activeNewTextStyleName = () => {
        graphicStore?.extraContext?.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle).dispatch(true);
    }

    const changeTextStyleName = (font, name: string) => {
        if (name.match(/^\s*$/)) {
            message.warning('此名称的长度必须至少为一个字符。');
            return;
        }
        if (graphicStore?.extraContext?.fontContext.state.fontList.some((item: Font) => item.uuid != font.uuid && item.name === name.trim())) {
            message.warning(`名称` + name.trim() + `已在使用。`);
            return;
        }
        let data = {
            ...font,
            name: name.trim(),
        }
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateTextStylePropertyCommand(font, data));
        // setState({
        //     renamingName: null
        // })
        setRenamingName(null)
    }

    const apply = () => {
        let font: Font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;
        // console.log(font);
        let data = {
            ...font,
            fontname: selectFontName,
            style: selectStyle,
            factor: selectFactor,
        }
        setApplyLoading(true)
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateTextStylePropertyCommand(font, data));
        setApplyLoading(false)
        checkApplyDisable()
    }

    const checkApplyDisable = () => {
        const font: Font = selectedFont || graphicStore?.extraContext?.fontContext.state.currentFont;

        if (font.fontname === selectFontName && font.style === selectStyle && font.factor === selectFactor) {
            setApplyDisable(true)
            return
        }
        setApplyDisable(false)
    }


    return (
        <div className="textStyle-manage-container">
            <div className="textStyle-header">
                <span>当前文字样式: </span>
                <span>{graphicStore?.extraContext?.fontContext.state.currentFont.name}</span>
            </div>
            <div className="textStyle-body">
                <div className="list-panel" style={{ width: '200px' }}>
                    <Space direction="vertical" size="small">
                        <div className="textStyle-list">
                            <span>文字样式: </span>
                        </div>
                        <div className="textStyle-list-panel" style={{ width: '200px' }}>
                            {graphicStore?.extraContext?.fontContext.state.fontList.map((font: Font, key) => (
                                <div key={key} className={classnames({
                                    "font-item": true,
                                    'active': font.uuid === graphicStore?.extraContext?.fontContext.state.currentFont?.uuid,
                                    'selected': font.uuid === selectedFont?.uuid,
                                })} onClick={() => onChangeSelectedFont(font)}>
                                    <div className="font-item-input">
                                        {renamingName === font.name && (
                                            <Input
                                                size="small"
                                                style={{ borderBlockColor: '##898989', width: '196px' }}
                                                defaultValue={font.name}
                                                ref={renameRef}
                                                onBlur={(e) => changeTextStyleName(font, e.target.value)}
                                                //@ts-ignore
                                                onPressEnter={(e) => changeTextStyleName(font, e.target.value)} />
                                        )}
                                    </div>
                                    <div className="font-item-name">
                                        {renamingName !== font.name && (font.name)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="textStyle-show">
                            <span>样式预览：</span>
                        </div>
                        {/* <Spin> */}
                            <div id="textStyle-show-panel" className="textStyle-show-panel" style={{ width: '200px' }}></div>
                        {/* </Spin> */}
                    </Space>
                </div>
                <div className="set-panel">
                    <Space direction="vertical" size="small">
                        <Card title="字体" size="small" style={{ width: 250 }}>
                            <Space direction="vertical" size="small">
                                <div className="textStyle-name">
                                    <span>字体名称：</span>
                                    <Select
                                        style={{ width: '150px' }}
                                        size="small"
                                        value={selectFontName}
                                        onChange={onChangeFontName}
                                        ref={ref}>
                                        {graphicStore?.extraContext?.fontContext.state.ttfNameList.map((font, key) => (
                                            <Option value={font.name} key={key}>
                                                {font.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="textStyle-style">
                                    <span>字体样式：</span>
                                    <Select
                                        style={{ width: '150px' }}
                                        size="small"
                                        value={selectStyle}
                                        onChange={onChangeFontStyle}
                                        ref={ref}>
                                        {graphicStore?.extraContext?.fontContext.state.ttfNameList.find(item => item.name === selectFontName)?.style.map((style, key) => (
                                            <Option value={style} key={key}>
                                                {style}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Space>
                        </Card>
                        <Card title="效果" size="small" style={{ width: 250, height: 160 }}>
                            <div className="textStyle-result">
                                <span>宽度因子：</span>
                                <Input
                                    ref={factorInputRef}
                                    value={selectFactor}
                                    onChange={(e) => {
                                        setSelectFactor(e.target.value.trim())
                                    }}
                                    size="small"
                                    style={{ width: '150px' }}
                                    onPressEnter={onCangeFactor}
                                    onBlur={onCangeFactor} />
                            </div>
                        </Card>
                    </Space>
                </div>
                <div className="btn-panel">
                    <Space direction="vertical" size="small">
                        <Button size="small" style={{ width: '80px', marginTop: '15px' }} onClick={setCurrentFont}>置为当前</Button>
                        <Button size="small" style={{ width: '80px' }} onClick={activeFontRenaming}>重命名</Button>
                        <Button size="small" style={{ width: '80px' }} onClick={activeNewTextStyleName}>新建</Button>
                        <Button size="small" danger style={{ width: '80px' }} onClick={ondelete}>删除</Button>
                    </Space>
                </div>
            </div>
            <div className="footer">
                <Space size="small">
                    <Button size="small" style={{ width: '60px' }} onClick={apply} loading={applyLoading} disabled={applyDisable}>应用</Button>
                    <Button size="small" style={{ width: '60px' }} onClick={props.onCancel}>关闭</Button>
                </Space>
            </div>
        </div>
    )
}
