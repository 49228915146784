import { Button, Input, message, Select, Space, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import './TextStyleSelect.less'
import Icon from '@ant-design/icons'
import { graphicStore } from '@/commons/store/graphic-store';
import { DragModal } from 'tncet-common';
import TextStyleManage from './TextStyleManage';
import { AddTextStyleCommand, CommandGroup, DTextT, ModelUtils, ToggleTextStyleCommand } from 'pytha';
import { Font } from '@/commons/interface/font';
import { ReactComponent as TextStyleSvg } from "@/commons/icons/text/textStyle.svg";
import { EXTRA_SINGAL } from '@/tool/enums/extra-singal';
import { findFontGlyphs } from '@/api/geometry/font';
import { createPrimitive } from '@/api/geometry/primitive';

const { Option } = Select;

export default function TextStyleSelect() {
    const ref = useRef<any>(null)
    const inputRef = useRef<any>(null)

    const [textStyleModalVisible, setTextStyleModalVisible] = useState<boolean>(false)
    const [textStyleNameModalVisible, setTextStyleNameModalVisible] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('new style')
    const [addLoading, setAddLoading] = useState<boolean>(false)
    const [currentFont, setCurrentFont] = useState<Font>(null)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            if (!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle)) {
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.activeNewTextStyle)
            }
            if (!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onTextStyleManageAwake)) {
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onTextStyleManageAwake)
            }
            graphicStore.extraContext.listeners.signals.onFontChange.add(onFontChange)
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle).add(activeNewTextStyle);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onTextStyleManageAwake).add(awakeTextStyleManage);
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.signals.onFontChange.add(onFontChange)
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle).add(activeNewTextStyle);
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onTextStyleManageAwake).add(awakeTextStyleManage);
            }, 5000)
        }

        return (() => {
            graphicStore.extraContext.listeners.signals.onFontChange.remove(onFontChange)
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onTextStyleManageAwake).remove(awakeTextStyleManage);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle).remove(activeNewTextStyle);
        })
    }, [])

    useEffect(() => {
        if (graphicStore?.extraContext?.fontContext?.state.currentFont) {
            setCurrentFont(graphicStore?.extraContext?.fontContext?.state.currentFont)
        }
    }, [graphicStore?.extraContext?.fontContext?.state.currentFont])

    const onFontChange = (font: Font) => {
        setCurrentFont({ ...font })
    }

    const onChange = async (name: string) => {
        let oldFont = graphicStore?.extraContext?.fontContext.state.currentFont;
        let font = graphicStore?.extraContext?.fontContext.state.fontList.find(item => item.name === name);
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleTextStyleCommand(oldFont, font));
        ref.current?.blur();

        // 获取选中的字图元
        let selectEntities = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList()?.filter(item => item.type == "DTextT")
        if (selectEntities?.length > 0) {
            for (let i = 0; i < selectEntities?.length; i++) {
                let textEntity = selectEntities[i] as DTextT
                let params = { ...graphicStore.extraContext.fontContext.findCurrentTtf(), text: textEntity.text }
                await findFontGlyphs(params).then(res => {
                    textEntity.fontData = { ...res.data }
                    textEntity.needUpdate = true
                    textEntity.updateViewGeometry()
                    createPrimitive(textEntity.toJson())
                })
            }
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
        }
    }

    const awakeTextStyleManage = () => {
        setTextStyleModalVisible(true)
    }

    const activeNewTextStyle = (isVisible) => {
        setTextStyleNameModalVisible(isVisible)
        if (!isVisible) return;
        setTimeout(() => {
            inputRef?.current?.focus({ cursor: 'all' });
        }, 0);
    }

    const addTextStyle = () => {
        let name = inputValue;
        let isSame = graphicStore?.extraContext?.fontContext.state.fontList.find(item => item.name === name);
        if (isSame) {
            message.error('文字样式重复命名');
            inputRef.current.focus({ cursor: 'all' });
            return;
        }
        let origin = graphicStore?.extraContext?.fontContext.state.fontList.find((item: Font) => item.originFont);
        let font = {
            ...origin,
            name: name,
            uuid: ModelUtils.generateUUID(),
            originFont: false,
        }
        setAddLoading(true)
        let addTextStyleCommand = new AddTextStyleCommand(font);
        let oldFont = graphicStore?.extraContext?.fontContext.state.currentFont;
        let toggleTextStyleCommand = new ToggleTextStyleCommand(oldFont, font);
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new CommandGroup([addTextStyleCommand, toggleTextStyleCommand]));
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.activeNewTextStyle).dispatch(false);
        setAddLoading(false)
    }

    return (
        <div className="textStyle-select">
            <Tooltip title="文字样式" color="#555555">
                <Button type="link" icon={<Icon style={{ fontSize: '14px' }} component={TextStyleSvg} />} onClick={() => graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('TEXTSTYLE')}></Button>
            </Tooltip>
            <Select
                style={{ width: '130px' }}
                size="small"
                value={currentFont?.name}
                onChange={onChange}
                ref={ref}>
                {graphicStore?.extraContext?.fontContext.state.fontList.map((font, key) => (
                    <Option value={font?.name} key={key}>
                        {
                            <div className="textStyle-select-option">
                                <div className="textStyle-select-name">{font?.name}</div>
                            </div>
                        }
                    </Option>
                ))}
            </Select>
            <DragModal
                visible={textStyleModalVisible}
                destroyOnClose
                centered={false}
                width={600}
                onClose={() => { setTextStyleModalVisible(false) }}
                title='文字样式'>
                <TextStyleManage
                    onCancel={() => {
                        setTextStyleModalVisible(false)
                    }}></TextStyleManage>
            </DragModal>
            <DragModal
                visible={textStyleNameModalVisible}
                destroyOnClose
                centered
                mask
                width={350}
                onClose={() => { setTextStyleNameModalVisible(false) }}
                title='新建文字样式'>
                <div className="addNewTextStyle">
                    <span>样式名：</span>
                    <Input
                        className="TextNameInput"
                        ref={inputRef}
                        size="small"
                        value={inputValue}
                        onChange={(e) => { setInputValue(e.target.value) }}
                        onPressEnter={() => addTextStyle()}
                        disabled={addLoading}></Input>
                    <Space direction="vertical" size="small">
                        <Button size="small" style={{ width: '80px' }} onClick={() => addTextStyle()} loading={addLoading}>确定</Button>
                        <Button size="small" danger style={{ width: '80px' }} onClick={() => { setTextStyleNameModalVisible(false) }}>取消</Button>
                    </Space>
                </div>
            </DragModal>
        </div>
    )
}