
import { graphicAxios } from "@/config/axios"
import { AxiosResponse } from "axios"

export function addProject(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/projects`, data, {
        params: {
            ...params,
        }
    })
}

export function findProjects(params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/projects`, {
        params: {
            ...params,
        }
    })
}
export function findProjectDetail(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/projects/${projectId}`, {
        params: {
            ...params,
        }
    })
}

export function updateProject(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.put(`/projects/${projectId}`, data, {
        params: {
            ...params,
        }
    })
}

export function deleteProject(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.delete(`/projects/${projectId}`, {
        params: {
            ...params,
        }
    })
}