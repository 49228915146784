import { Button, Input, message, Modal, Select, Space, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react'
import './LayerManage.less';
import Icon, { PlusOutlined, MinusOutlined, CheckOutlined, BulbFilled, BulbOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import ColorPalette from '../color/ColorPalette';
import { ReactComponent as OneLayerSvg } from '@/commons/icons/layer/oneLayer.svg';
import classnames from 'classnames';
import { AddLayerCommand, Layer, RemoveLayerCommand, ToggleLayerCommand, ToggleLayerHiddenCommand, ToggleLayerLockCommand, UpdateLayerColorCommand, UpdateLayerLineWidthCommand, UpdateLayerPropertyCommand } from 'pytha';
import { ContextMenuModal, DragModal } from 'tncet-common';
import genUUID from '@/commons/utils/gen-uuid';
import { graphicStore } from '@/commons/store/graphic-store';
import { lineWidthList } from '../line-width/LwSelect';

const { Option } = Select;

export default function LayerManage() {

    const [paletteModalVisible, setPaletteModalVisible] = useState<boolean>(false)
    const [selectedLayer, setSelectedLayer] = useState<any>(null)
    const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false)
    const [contextMenuLeft, setContextMenuLeft] = useState<number>(0)
    const [contextMenuTop, setContextMenuTop] = useState<number>(0)

    const [tableData, setTableData] = useState<Layer[]>([])

    const currentLayer = graphicStore.context.layerContext.layers.find((item: Layer) => item.uuid === graphicStore.context.layerContext.currentLayerUuid);
    const selected: Layer = selectedLayer || currentLayer;

    useEffect(() => {
        graphicStore.extraContext.listeners.signals.onLayerChanged.add(generateLayerData)
        return (() => {
            graphicStore.extraContext.listeners.signals.onLayerChanged.remove(generateLayerData)
        })
    }, [])

    useEffect(() => {
        const newTableData = graphicStore?.context?.layerContext?.layers || [];
        setTableData([...newTableData]);
    }, [])

    const generateLayerData = (layers: Layer[]) => {
        setTableData(layers)
    }

    const onColorSelectClick = (record) => {
        setSelectedLayer(record)
        setPaletteModalVisible(true)
    }

    const onColorSelectComplete = (color) => {
        setPaletteModalVisible(false)
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateLayerColorCommand(selectedLayer, color));
    }

    const onHiddenChange = (record: Layer) => {
        setSelectedLayer(record)
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new ToggleLayerHiddenCommand(record, !record.hidden));
    }

    const onLockChange = (record: Layer) => {
        setSelectedLayer(record)
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new ToggleLayerLockCommand(record, !record.lock));
        setContextMenuVisible(false)
    }

    const chooseCurrentLayer = (record: Layer) => {
        if (!record) return;
        let currentLayer = graphicStore.context.layerContext.layers.find((item: Layer) => item.uuid === graphicStore.context.layerContext.currentLayerUuid);
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new ToggleLayerCommand(currentLayer, record));
        setContextMenuVisible(false)
    }

    const addLayer = (record: Layer) => {
        let uuid = genUUID();
        let newLayer = { ...record, uuid: uuid, name: record.name + '-' + uuid.slice(0, 6), originLayer: false, type: undefined };
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new AddLayerCommand(newLayer));
        setContextMenuVisible(false)
    }

    const removeLayer = (record: Layer) => {
        if (Object.keys(graphicStore?.extraContext?.getCurrentViewEditor().entityDict).some(key =>
            graphicStore?.extraContext?.getCurrentViewEditor().entityDict[key].layerUuid === record.uuid)
            || !!record.originLayer
            || record.uuid === graphicStore.context.layerContext.currentLayerUuid
            || !!record?.type) {
            Modal.error({
                title: `图层 ${record.name} 删除失败`,
                maskClosable: true,
                content: (
                    <div style={{ marginTop: '20px' }}>
                        <div>以下图层无法删除：</div>
                        <ul>
                            <li>0图层</li>
                            <li>当前图层</li>
                            <li>包含对象的图层</li>
                            <li>轴网、路网等特殊图层</li>
                        </ul>
                    </div>
                )
            })
            return;
        }
        let idx = graphicStore.context.layerContext.layers.findIndex(item => item.uuid === record.uuid);

        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new RemoveLayerCommand(record));
        if (graphicStore.context.layerContext.layers.length > 0) {
            if (idx >= graphicStore.context.layerContext.layers.length) {
                idx--;
            }
            let selected = graphicStore.context.layerContext.layers[idx];
            setSelectedLayer(selected)
        }
        setContextMenuVisible(false)
    }

    const changeLayerName = (record: Layer, value: string) => {
        if (value.match(/^\s*$/)) {
            message.warning('此名称的长度必须至少为一个字符。');
            return;
        }
        if (graphicStore.context.layerContext.layers.some((item: Layer) => item.uuid != record.uuid && item.name === value.trim())) {
            message.warning(`名称` + value.trim() + `已在使用。`);
            return;
        }
        record.renaming = false;
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateLayerPropertyCommand(record, { name: value.trim() }));
    }

    const onLineWidthChange = (record: Layer, e: number) => {
        setSelectedLayer(record)
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateLayerLineWidthCommand(record, e));

    }

    return (
        <div className="layer-manage-container">
            <div className="manage-header">
                <Space>
                    <Tooltip title="新建">
                        <Button size="small" type="dashed" icon={<PlusOutlined />} onClick={() => addLayer(selected)}></Button>
                    </Tooltip>
                    <Tooltip title="删除">
                        <Button size="small" type="dashed" icon={<MinusOutlined />} onClick={() => removeLayer(selected)}></Button>
                    </Tooltip>
                    <Tooltip title="置为当前">
                        <Button size="small" type="dashed" icon={<CheckOutlined style={{ color: '#57a500' }} />} onClick={() => chooseCurrentLayer(selected)}></Button>
                    </Tooltip>
                </Space>
            </div>
            <div className="manage-body">
                <Table
                    rowKey="uuid"
                    bordered
                    dataSource={tableData}
                    pagination={false}
                    sticky
                    onRow={(record) => {
                        return {
                            onClick: event => { setSelectedLayer(record) },
                            onContextMenu: event => {
                                setSelectedLayer(record)
                                setContextMenuVisible(true)
                                setContextMenuLeft(event.clientX)
                                setContextMenuTop(event.clientY)
                                event.preventDefault();
                            },
                            onDoubleClick: event => {
                                chooseCurrentLayer(record);
                            }
                        }
                    }}
                    rowClassName={(record, idx) => {
                        return classnames({
                            'row-selected': record.uuid === selected?.uuid
                        })
                    }}
                >
                    <Table.Column title="状态" key="status" width="55px" render={(record: Layer) => (
                        <div>
                            {graphicStore.context.layerContext.currentLayerUuid === record.uuid && (
                                <CheckOutlined style={{ color: '#57a500' }} />
                            )}
                            {graphicStore.context.layerContext.currentLayerUuid !== record.uuid && (
                                <Icon component={OneLayerSvg} style={{ color: '#0168B3' }} />
                            )}
                        </div>
                    )} />
                    <Table.Column title="名称" key="name" ellipsis render={(record: Layer) => {
                        return !!record.renaming ? (
                            <Input
                                size="small"
                                bordered={false}
                                style={{ backgroundColor: '#fff' }}
                                defaultValue={record.name}
                                onBlur={(e) => { changeLayerName(record, e.target.value) }}
                                //@ts-ignore
                                onPressEnter={e => changeLayerName(record, e.target.value)} />
                        ) : (
                            <div style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }}>{record.name}</div>
                        )
                    }} sorter={(a: Layer, b: Layer) => a.name > b.name ? 1 : -1} />
                    <Table.Column title="显示" key="hidden" width="70px" render={(record: Layer) => (
                        <div className="visible-select" onClick={() => onHiddenChange(record)}>
                            {!record.hidden ? <BulbFilled /> : <BulbOutlined style={{ color: '#44C1F8' }} />}
                        </div>
                    )} />
                    <Table.Column title="冻结" key="lock" width="70px" render={(record: Layer) => (
                        <div className="lock-select" onClick={() => onLockChange(record)}>
                            {record.lock ? <LockOutlined style={{ color: '#DB1919' }} /> : <UnlockOutlined style={{ color: '#0168B3' }} />}
                        </div>
                    )} />
                    <Table.Column title="颜色" key="color" width="120px" render={(record: Layer) => (
                        <div className="color-select" onClick={() => onColorSelectClick(record)}>
                            <div className="color-box" style={{ backgroundColor: record.color }}></div>
                            <div>
                                {record.color}
                            </div>
                        </div>
                    )} />
                    <Table.Column title="线宽" key="lineWidth" width="150px" render={(record: Layer) => (
                        <Select
                            dropdownStyle={{ backgroundColor: '#757575' }}
                            style={{ width: '100%' }}
                            size="small"
                            value={record.lineWidth}
                            onChange={(e) => onLineWidthChange(record, e)}>
                            {lineWidthList.map((item, key) => (
                                <Option value={+item} key={key}>
                                    <div className="lw-select-option">
                                        <div className="lw-select-box" style={{ height: item * graphicStore.context.renderContext.lineWidthDisplayScale || 1 }}></div>
                                        <div className="lw-select-name">{item.toFixed(2) + '毫米'}</div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    )} />
                </Table>
            </div>

            <DragModal
                title="选择颜色"
                visible={paletteModalVisible}
                width={550}
                wrapClassName="color-select-modal"
                destroyOnClose
                onClose={() => { setPaletteModalVisible(false) }}>
                <div className="color-select-modal-body">
                    <ColorPalette
                        defaultColor={selected?.color}
                        onCancel={() => { setPaletteModalVisible(false) }}
                        onComplete={onColorSelectComplete}></ColorPalette>
                </div>

            </DragModal>


            <ContextMenuModal
                visible={contextMenuVisible}
                left={contextMenuLeft}
                top={contextMenuTop}
                wrapperClassname='layer-context-menu-wrapper'
                onCancel={() => { setContextMenuVisible(false) }}
            >
                <div className="layer-context-menu">
                    <div className="menu-item" onClick={() => chooseCurrentLayer(selected)}>置为当前</div>
                    <div className="menu-item" onClick={() => addLayer(selected)}>新建图层</div>
                    <div className="menu-item" onClick={() => removeLayer(selected)}>删除图层</div>
                    <div className="menu-item" onClick={() => {
                        if (selected.originLayer) {
                            message.error('图层0无法重命名')
                            setContextMenuVisible(false)
                            return;
                        }
                        selected.renaming = true;
                        graphicStore.context.layerContext.setLayers(graphicStore.context.layerContext.layers)
                        setContextMenuVisible(false)
                    }}>重命名</div>
                </div>
            </ContextMenuModal>
        </div>
    )
}

