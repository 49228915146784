import { MOUSE, BasicControl, CONTROL_TYPE, TnEngineContext, TnEngineExtraContext, ViewEditor, Listeners, CURSOR_TYPE, DYMODE_MODE, Point, BlockT, dymode } from "pytha";
import { Vector3 } from "three";
import { InsertBlockCommand } from "../commands/InsertBlockCommand";

import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";


export class InsertBlockControl extends BasicControl {

    id = CONTROL_TYPE.INSERT_BLOCK;

    point_down: Vector3 = new Vector3();

    capturePoint: Vector3;

    step: number = 1;

    basePoint: Vector3 = new Vector3();

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.editor = editor;
        this.listeners = listeners;
        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();
        //捕捉
        this.listeners.signals.needCapture.dispatch();
        this.listeners.signals.onPointCapture.add(this.onPointCapture);
        //光标
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
        //动态输入重置
        this.extraContext.dymodeContext.reset();
        this.editor.listeners.signals.onDymodeVisible.dispatch(false);
        //插入块弹窗
        this.listeners.getSignal(EXTRA_SINGAL.onBlockInsertAwake).dispatch();
        this.listeners.getSignal(EXTRA_SINGAL.onBlockInsertSelectPoint).add(this.selectPoint);
        this.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsert).add(this.insertBlock);
        this.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertOff).add(this.cancle);
        console.log('InsertBlockControl added')
    }

    dispose(): void {
        this.listeners.getSignal(EXTRA_SINGAL.onBlockInsertSelectPoint).remove(this.selectPoint);
        this.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsert).remove(this.insertBlock);
        this.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertOff).remove(this.cancle);
        this.editor.selectControl.setEnabled(true);
        this.listeners.signals.onZoomChanged.dispatch(1, null, true);
        console.log('InsertBlockControl removed')
    }


    onPointCapture = (point) => {
        this.capturePoint = point;
    }

    selectPoint = () => {
        const domElement = this.editor.renderer?.domElement;
        domElement?.focus();
        this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
        this.extraContext.dymodeContext.enabled = true;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.POINT);
        this.extraContext.dymodeContext.setDymodeTip('指定插入点:');
        this.extraContext.stmodeContext.setStmode('指定插入点', []);
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
    }

    insertBlock = (blockJson, basePoint: Point, angle: number, rotateX: number, rotateY: number, rotateZ: number) => {
        if (!blockJson) {
            this.extraContext.stmodeContext.pushLog('尚未选择块');
            return;
        }
        let block = new BlockT();
        block.fromJson(blockJson);
        let newBlock = block.clone();
        newBlock.render();
        let moveVector = basePoint.sub(newBlock.position);
        let dir = moveVector.clone().normalize();
        let dist = moveVector.length();
        newBlock.move(dir, dist);
        if (angle > 0) {
            newBlock.rotate(newBlock.position, newBlock.normal, angle);
        }
        if (rotateX > 0) {
            newBlock.rotate(newBlock.position, newBlock.axisX, rotateX);
        }
        if (rotateY > 0) {
            let axisY = newBlock.normal.clone().cross(newBlock.axisX);
            newBlock.rotate(newBlock.position, axisY, rotateY);
        }
        if (rotateZ > 0) {
            newBlock.rotate(newBlock.position, newBlock.normal, rotateZ);
        }
        this.editor.history.execute(new InsertBlockCommand(this.editor, newBlock));
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    cancle = () => {
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    onPointerDown = (event) => {
        if (event.button !== MOUSE.LEFT) return;
        this.point_down.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        if (this.capturePoint) {
            this.point_down.copy(this.capturePoint);
        }
        this.basePoint.copy(this.point_down);
        this.extraContext.dymodeContext.enabled = false;
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint).dispatch(this.point_down.x, this.point_down.y, this.point_down.z);
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.onPointCapture.remove(this.onPointCapture);
        this.listeners.signals.noNeedCapture.dispatch();
    }

    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this.basePoint.copy(this.point_down);
            this.extraContext.dymodeContext.enabled = false;
            this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
            this.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint).dispatch(this.point_down.x, this.point_down.y, this.point_down.z);
            this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
            this.listeners.signals.onPointCapture.remove(this.onPointCapture);
            this.listeners.signals.noNeedCapture.dispatch();
        }
    }

}