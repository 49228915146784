
import { Dropdown, Tooltip } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import './ColorSetting.less';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import Icon from '@ant-design/icons'
import ColorPalette from '../color/ColorPalette';
import { ModelBase, ToggleDrawColorCommand, ToggleEntityColorCommand } from 'pytha';
import { graphicStore } from '@/commons/store/graphic-store';
import { HexStringToKeywords } from '@/commons/utils/color-utils';
import { ReactComponent as PaletteSvg } from '@/commons/icons/properties/palette.svg';
import { DragModal } from 'tncet-common';


const colorList = [
    '#000000', '#f8d731', '#cd6928', '#991b1e', '#642165', '#293189', '#22346e', '#136734', '#727430',
    '#636466', '#f1eb1f', '#f26722', '#cd2027', '#93278f', '#4554a5', '#105689', '#139b48', '#999b37',
    '#939598', '#f4ee51', '#f8991e', '#ed1f24', '#a953a0', '#5e67af', '#2776bb', '#58ba48', '#c8c92d',
    '#c7c8ca', '#f7f281', '#fecc66', '#f27172', '#d19cc7', '#9898cc', '#7aafdf', '#afd9aa', '#e5e878',
    '#ffffff', '#f8f6b0', '#feeab9', '#f7abae', '#ddb3d4', '#cecce6', '#adddf7', '#d7ebd2', '#edee99',
]
const indexColorList = [
    '#ff0000',
    '#ffff00',
    '#00ff00',
    '#00ffff',
    '#0000ff',
    '#ff00ff',
    '#ffffff',
    '#808080',
    '#c0c0c0',
]

export default function ColorSetting() {
    const [dropVisible, setDropVisible] = useState<boolean>(false)
    const [currentColor, setCurrentColor] = useState<string>("")
    const [isColorByLayer, setIsColorByLayer] = useState<boolean>(true)
    const [hasSelected, setHasSelected] = useState<boolean>(false)
    const [paletteModalVisible, setPaletteModalVisible] = useState<boolean>(false)
    const [showProperty, setShowProperty] = useState<boolean>(true)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            graphicStore.extraContext.listeners.signals.onFilterChanged.add(onFilterChanged)
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.signals.onFilterChanged.add(onFilterChanged)
            }, 5000)
        }
        return (() => {
            graphicStore.extraContext.listeners.signals.onFilterChanged.remove(onFilterChanged)

        })
    }, [])

    const onFilterChanged = (entities: ModelBase[]) => {
        let selects = entities;
        if (selects.length === 0) {
            // 没有东西选中
            setCurrentColor(graphicStore?.context.layerContext.currentColor)
            setHasSelected(false)
            setShowProperty(true)
            setIsColorByLayer(graphicStore?.context.layerContext.isColorByLayer)
            return;
        }
        let isColorByLayer;
        for (let selected of selects) {
            if (isColorByLayer == null) {
                isColorByLayer = selected.isColorByLayer;
            } else if (isColorByLayer !== selected.isColorByLayer) {
                // color = '';
                isColorByLayer = null;
                setShowProperty(false)
                setHasSelected(true)
                return;
            }
        }
        let color;
        if (!!isColorByLayer) {
            // 如果选中的对象颜色都是随层
            color = graphicStore?.context.layerContext.currentLayerColor;
        } else {
            for (let selected of selects) {
                if (color == null) {
                    color = `#${selected.color.getHexString()}`;
                } else if (color !== `#${selected.color.getHexString()}`) {
                    color = null;
                    setShowProperty(false)
                    setHasSelected(true)
                    return;
                }
            }
        }
        setCurrentColor(color)
        setIsColorByLayer(isColorByLayer)
        setHasSelected(true)
        setShowProperty(true)
    }

    const onComplete = (color) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = graphicStore.extraContext.getCurrentViewEditor()?.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: false,
            }, {
                color: graphicStore?.context.layerContext.currentColor,
                isColorByLayer: graphicStore?.context.layerContext.isColorByLayer,
            }))

        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, false));
        }
        graphicStore.extraContext.listeners.signals.onColorChanged.dispatch();
        setPaletteModalVisible(false)
        setDropVisible(false)
        setCurrentColor(color)
        setIsColorByLayer(false)
        setShowProperty(true)
        editor.renderer.domElement.focus();
    }

    const chooseByLayer = () => {
        let editor = graphicStore.extraContext.getCurrentViewEditor()
        let selects = editor.selectControl.getSelectedEntityList();
        let color = graphicStore?.context.layerContext.currentLayerColor;
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: true,
            }, {
                color: graphicStore?.context.layerContext.currentColor,
                isColorByLayer: graphicStore?.context.layerContext.isColorByLayer,
            }))
        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, true));
        }
        graphicStore.extraContext.listeners.signals.onColorChanged.dispatch();
        setPaletteModalVisible(false)
        setDropVisible(false)
        setCurrentColor(color)
        setIsColorByLayer(true)
        setShowProperty(true)
    }

    const content = (
        <div className="color-select-dropdown">
            <div className="click-select" onClick={chooseByLayer}>
                <div className="color-box" style={{ backgroundColor: graphicStore?.context?.layerContext?.currentLayerColor }}></div>
                <div className="color-name">ByLayer</div>
            </div>
            <div className="divider"></div>
            <div className="color-select">
                <div className="color-wrapper">
                    {colorList.map((color, idx) => (
                        <Tooltip title={color} key={idx} color="#fff" overlayClassName="color-tooltip">
                            <div className="color-item" onClick={() => onComplete(color)}>
                                <div className="color-box" style={{ backgroundColor: color }}></div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </div>
            <div className="divider"></div>
            <div className="color-select">
                <div>索引颜色</div>
                <div className="color-wrapper">
                    {indexColorList.map((color, idx) => (
                        <Tooltip title={HexStringToKeywords[color] || color} key={idx} color="#fff" overlayClassName="color-tooltip">
                            <div className="color-item" onClick={() => onComplete(color)}>
                                <div className="color-box" style={{ backgroundColor: color }}></div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <div className="click-select" style={{ marginTop: '4px' }} onClick={() => {
                    setPaletteModalVisible(true)
                    setDropVisible(false)
                }}>
                    <Icon className="palette-icon" component={PaletteSvg} />
                    <span>更多颜色...</span>
                </div>
            </div>
        </div>
    )

    return (
        <div className="color-setting">
            <Dropdown
                overlay={content}
                trigger={['click']}
                visible={dropVisible}
                onVisibleChange={(e) => {
                    setDropVisible(e)
                }}
                placement="bottomLeft"
            >
                <div className={classnames({
                    'current-color-wrapper': true,
                    'active': dropVisible
                })}>
                    <span className="current-item">
                        {!hasSelected && (
                            <Fragment>
                                <div className="color-box" style={{
                                    backgroundColor: graphicStore?.context.layerContext.isColorByLayer ? graphicStore?.context?.layerContext.currentLayerColor : graphicStore?.context.layerContext.currentColor
                                }}
                                ></div>
                                <div>
                                    {graphicStore?.context.layerContext.isColorByLayer ? 'ByLayer' : graphicStore?.context.layerContext.currentColor}
                                </div>
                            </Fragment>
                        )}
                        {!!hasSelected && showProperty && (
                            <Fragment>
                                <div className="color-box" style={{ backgroundColor: currentColor }}></div>
                                <div>
                                    {isColorByLayer ? 'ByLayer' : currentColor}
                                </div>
                            </Fragment>
                        )}
                    </span>
                    <span className="ant-select-arrow" style={{ userSelect: 'none' }}>
                        <DownOutlined />
                    </span>
                </div>
            </Dropdown>

            <DragModal
                title="选择颜色"
                visible={paletteModalVisible}
                width={550}
                wrapClassName="color-select-modal"
                onClose={() => { setPaletteModalVisible(false) }}>
                <div className="color-select-modal-body">
                    <ColorPalette
                        onCancel={() => { setPaletteModalVisible(false) }}
                        onComplete={onComplete}></ColorPalette>
                </div>

            </DragModal>
        </div>
    )
}
