// import { Dropdown, Tooltip } from 'antd'
// import { Component, Fragment } from 'react'
// import './ColorSelect.less';
// import classnames from 'classnames';
// import { ReactComponent as PaletteSvg } from '@/commons/icons/color/palette.svg'
// import ColorPalette from '@/commons/components/color/ColorPalette';
// import { DragModal } from 'tncet-common';
// import Listeners from 'pytha/dist/tn-engine-extra/listeners';
// import { ViewEditor } from 'pytha';
// import { graphicStore } from '@/commons/store/graphic-store';
// import { ReactComponent as DownArrowSvg } from '@/commons/icons/color/down-arrow-dark-small.svg'

// const colorList = [
//     '#000000', '#f8d731', '#cd6928', '#991b1e', '#642165', '#293189', '#22346e', '#136734', '#727430',
//     '#636466', '#f1eb1f', '#f26722', '#cd2027', '#93278f', '#4554a5', '#105689', '#139b48', '#999b37',
//     '#939598', '#f4ee51', '#f8991e', '#ed1f24', '#a953a0', '#5e67af', '#2776bb', '#58ba48', '#c8c92d',
//     '#c7c8ca', '#f7f281', '#fecc66', '#f27172', '#d19cc7', '#9898cc', '#7aafdf', '#afd9aa', '#e5e878',
//     '#ffffff', '#f8f6b0', '#feeab9', '#f7abae', '#ddb3d4', '#cecce6', '#adddf7', '#d7ebd2', '#edee99',
// ]
// const indexColorList = [
//     '#ff0000',
//     '#ffff00',
//     '#00ff00',
//     '#00ffff',
//     '#0000ff',
//     '#ff00ff',
//     '#ffffff',
//     '#808080',
//     '#c0c0c0',
// ]

// const HexStringToKeywords = {
//     '#ff0000': '红',
//     '#ffff00': '黄',
//     '#00ff00': '绿',
//     '#00ffff': '青',
//     '#0000ff': '蓝',
//     '#ff00ff': '洋红',
//     '#ffffff': '白',
//     '#808080': '8',
//     '#c0c0c0': '9',
// }

// export interface VProps {
//     listeners?: Listeners
//     editor?: ViewEditor
// }

// interface IProps extends VProps {
//     layerStore?: any
//     selectColor?: (color) => void,
//     selectByLayer?: () => void,
// }

// export default class ColorChange extends Component<IProps> {
//     state = {
//         dropVisible: false,
//         /// 当有东西选中时，用此变量显示选择器
//         currentColor: '#fff',
//         isColorByLayer: true,
//         ///

//         paletteModalVisible: false,
//         showProperty: true,

//     }

//     componentDidMount() {
//         this.setState({
//             currentColor: graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor,
//             showProperty: true,
//             isColorByLayer: true,
//         })
//     }

//     onComplete = (color) => {
//         let editor = this.props.editor;
//         this.setState({
//             paletteModalVisible: false,
//             dropVisible: false,
//             currentColor: color,
//             isColorByLayer: false,
//             showProperty: true,
//         })
//         editor.renderer.domElement.focus();
//         this.props.selectColor(color);
//     }

//     chooseByLayer = () => {
//         let color = graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor;
//         this.setState({
//             paletteModalVisible: false,
//             dropVisible: false,
//             showProperty: true,
//             currentColor: color,
//             isColorByLayer: true,
//         })
//         if (this.props.selectByLayer) {
//             this.props.selectByLayer();
//         }
//     }

//     render() {
//         const content = (
//             <div className="color-select-dropdown">
//                 <div className="click-select" onClick={this.chooseByLayer}>
//                     <div className="color-box"
//                         style={{
//                             backgroundColor: graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor
//                         }}>
//                     </div>
//                     <div className="color-name">ByLayer</div>
//                 </div>
//                 <div className="divider"></div>
//                 <div className="color-select">
//                     <div className="color-wrapper">
//                         {colorList.map((color, idx) => (
//                             <Tooltip title={color} key={idx} color="#fff" overlayClassName="color-tooltip">
//                                 <div className="color-item" onClick={() => this.onComplete(color)}>
//                                     <div className="color-box" style={{ backgroundColor: color }}></div>
//                                 </div>
//                             </Tooltip>
//                         ))}
//                     </div>
//                 </div>
//                 <div className="divider"></div>
//                 <div className="color-select">
//                     <div>索引颜色</div>
//                     <div className="color-wrapper">
//                         {indexColorList.map((color, idx) => (
//                             <Tooltip title={HexStringToKeywords[color] || color} key={idx} color="#fff" overlayClassName="color-tooltip">
//                                 <div className="color-item" onClick={() => this.onComplete(color)}>
//                                     <div className="color-box" style={{ backgroundColor: color }}></div>
//                                 </div>
//                             </Tooltip>
//                         ))}
//                     </div>
//                 </div>
//                 <div className="divider"></div>
//                 <div>
//                     <div className="click-select" style={{ marginTop: '4px' }} onClick={() => {
//                         this.setState({
//                             paletteModalVisible: true,
//                             dropVisible: false,
//                         })
//                     }}>
//                         {/* <Icon className="palette-icon" component={PaletteSvg} /> */}
//                         <PaletteSvg />
//                         <span>更多颜色...</span>
//                     </div>
//                 </div>
//             </div>
//         )
//         return (
//             <div className="color-select">
//                 <Dropdown
//                     overlay={content}
//                     trigger={['click']}
//                     open={this.state.dropVisible}
//                     onOpenChange={(e) => {
//                         this.setState({ dropVisible: e })
//                     }}
//                     placement="bottomLeft"
//                 >
//                     <div className={classnames({
//                         'current-color-wrapper': true,
//                         'active': this.state.dropVisible
//                     })}>
//                         <span className="current-item">
//                             <Fragment>
//                                 <div className="color-box" style={{
//                                     backgroundColor: this.state.isColorByLayer ? graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor : this.state.currentColor
//                                 }}
//                                 ></div>
//                                 <div>
//                                     {this.state.isColorByLayer ? 'ByLayer' : this.state.currentColor}
//                                 </div>
//                             </Fragment>
//                         </span>
//                         <span className="ant-select-arrow" style={{ userSelect: 'none' }}>
//                             <DownArrowSvg width={6} height={3} />
//                         </span>
//                     </div>
//                 </Dropdown>

//                 <DragModal
//                     title="选择颜色"
//                     visible={this.state.paletteModalVisible}
//                     width={550}
//                     wrapClassName="color-select-modal"
//                     onClose={() => this.setState({ paletteModalVisible: false })}>
//                     <div className="color-select-modal-body">
//                         <ColorPalette
//                             onCancel={() => this.setState({ paletteModalVisible: false })}
//                             onComplete={this.onComplete}></ColorPalette>
//                     </div>

//                 </DragModal>
//             </div>
//         )
//     }
// }
import { Dropdown, Tooltip } from 'antd';
import { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { ReactComponent as PaletteSvg } from '@/commons/icons/color/palette.svg';
import ColorPalette from '@/commons/components/color/ColorPalette';
import { DragModal } from 'tncet-common';
import { graphicStore } from '@/commons/store/graphic-store';
import { ReactComponent as DownArrowSvg } from '@/commons/icons/color/down-arrow-dark-small.svg';
import { Listeners, ToggleDrawColorCommand, ToggleEntityColorCommand, ViewEditor } from 'pytha';
import './ColorSelect.less';

const colorList = [
    '#000000', '#f8d731', '#cd6928', '#991b1e', '#642165', '#293189', '#22346e', '#136734', '#727430',
    '#636466', '#f1eb1f', '#f26722', '#cd2027', '#93278f', '#4554a5', '#105689', '#139b48', '#999b37',
    '#939598', '#f4ee51', '#f8991e', '#ed1f24', '#a953a0', '#5e67af', '#2776bb', '#58ba48', '#c8c92d',
    '#c7c8ca', '#f7f281', '#fecc66', '#f27172', '#d19cc7', '#9898cc', '#7aafdf', '#afd9aa', '#e5e878',
    '#ffffff', '#f8f6b0', '#feeab9', '#f7abae', '#ddb3d4', '#cecce6', '#adddf7', '#d7ebd2', '#edee99',
];
const indexColorList = [
    '#ff0000',
    '#ffff00',
    '#00ff00',
    '#00ffff',
    '#0000ff',
    '#ff00ff',
    '#ffffff',
    '#808080',
    '#c0c0c0',
];

const HexStringToKeywords = {
    '#ff0000': '红',
    '#ffff00': '黄',
    '#00ff00': '绿',
    '#00ffff': '青',
    '#0000ff': '蓝',
    '#ff00ff': '洋红',
    '#ffffff': '白',
    '#808080': '8',
    '#c0c0c0': '9',
};

export interface VProps {
    listeners?: Listeners
    editor?: ViewEditor
}

interface IProps extends VProps {
    layerStore?: any
    selectColor?: (color) => void,
    selectByLayer?: () => void,
}

export default function ColorChange(props: IProps) {

    const [dropVisible, setDropVisible] = useState(false);
    const [currentColor, setCurrentColor] = useState('#fff');
    const [isColorByLayer, setIsColorByLayer] = useState(true);
    const [paletteModalVisible, setPaletteModalVisible] = useState(false);

    useEffect(() => {
        setCurrentColor(graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor);
        setIsColorByLayer(true);
    }, []);

    useEffect(() => {
        setCurrentColor(graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor);
    }, [graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor]);

    useEffect(() => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return ()=>{};
        // 注册修改颜色
        graphicStore.extraContext.listeners.signals.onSelectChanged.add(onSelectChanged);
        graphicStore.extraContext.listeners.signals.onColorChanged.add(onSelectChanged);


        return () => {
            if (!graphicStore.extraContext.getCurrentViewEditor()) return;
            graphicStore.extraContext.listeners.signals.onSelectChanged.remove(onSelectChanged);
            graphicStore.extraContext.listeners.signals.onColorChanged.remove(onSelectChanged);
        }
    }, [graphicStore?.extraContext?.getCurrentViewEditor()])

    const onSelectChanged = (entities) => {
        if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            // 没有东西选中
            // setCurrentColor(graphicStore.context.layerContext.currentLayerColor);
            // setIsColorByLayer(graphicStore.context.layerContext.isColorByLayer);
            return;
        }

        let colorByLayer = null;
        for (let selected of selects) {
            if (colorByLayer === null) {
                colorByLayer = selected.isColorByLayer;
            } else if (colorByLayer !== selected.isColorByLayer) {
                return;
            }
        }

        let color = null;
        if (colorByLayer) {
            // 如果选中的对象颜色都是随层
            color = graphicStore.context.layerContext.currentLayerColor;
        } else {
            for (let selected of selects) {
                if (color === null) {
                    color = `#${selected.color.getHexString()}`;
                } else if (color !== `#${selected.color.getHexString()}`) {
                    return;
                }
            }
        }

        setCurrentColor(color);
        setIsColorByLayer(colorByLayer);
    };

    const onComplete = (color) => {
        let editor = graphicStore?.extraContext?.getCurrentViewEditor()
        setPaletteModalVisible(false);
        setDropVisible(false);
        setCurrentColor(color);
        setIsColorByLayer(false);
        // setShowProperty(true);
        let selects = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawColorCommand(
                {
                    color: color,
                    isColorByLayer: false,
                },
                {
                    color: graphicStore.context.layerContext.currentColor,
                    isColorByLayer: graphicStore.context.layerContext.isColorByLayer
                }
            ))
        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, false));
        }

        editor?.renderer.domElement.focus();
        if (props?.selectColor) {
            props.selectColor(color);
        }
    };

    const chooseByLayer = () => {
        let color = graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor;
        setPaletteModalVisible(false);
        setDropVisible(false);
        setCurrentColor(color);
        setIsColorByLayer(true);
        if (props?.selectByLayer) {
            props.selectByLayer();
        } else {
            let editor = graphicStore.extraContext.getCurrentViewEditor()
            let selects = editor.selectControl.getSelectedEntityList();
            if (selects.length === 0) {
                editor.history.execute(new ToggleDrawColorCommand({
                    color: color,
                    isColorByLayer: true,
                }, {
                    color: graphicStore.context.layerContext.currentColor,
                    isColorByLayer: graphicStore.context.layerContext.isColorByLayer
                }))
            } else {
                editor.history.execute(new ToggleEntityColorCommand(selects, color, true));
            }
        }
    };

    const content = (
        <div className="color-select-dropdown">
            <div className="click-select" onClick={chooseByLayer}>
                <div className="color-box"
                    style={{ backgroundColor: graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor }}>
                </div>
                <div className="color-name">ByLayer</div>
            </div>
            <div className="divider"></div>
            <div className="color-select">
                <div className="color-wrapper">
                    {colorList.map((color, idx) => (
                        <Tooltip title={color} key={idx} color="#fff" overlayClassName="color-tooltip">
                            <div className="color-item" onClick={() => onComplete(color)}>
                                <div className="color-box" style={{ backgroundColor: color }}></div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </div>
            <div className="divider"></div>
            <div className="color-select">
                <div>索引颜色</div>
                <div className="color-wrapper">
                    {indexColorList.map((color, idx) => (
                        <Tooltip title={HexStringToKeywords[color] || color} key={idx} color="#fff" overlayClassName="color-tooltip">
                            <div className="color-item" onClick={() => onComplete(color)}>
                                <div className="color-box" style={{ backgroundColor: color }}></div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <div className="click-select" style={{ marginTop: '4px' }} onClick={() => {
                    setPaletteModalVisible(true);
                    setDropVisible(false);
                }}>
                    <PaletteSvg />
                    <span>更多颜色...</span>
                </div>
            </div>
        </div>
    );

    return (
        <Tooltip title="颜色" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
            <div className="color-select">
                <Dropdown
                    overlay={content}
                    trigger={['hover']}
                    open={dropVisible}
                    onOpenChange={(e) => {
                        setDropVisible(e)
                    }}
                    placement="bottomLeft"
                >
                    <div className={classnames({
                        'current-color-wrapper': true,
                        'active': dropVisible
                    })}>
                        <span className="current-item">
                            <Fragment>
                                <div className="color-box" style={{
                                    // backgroundColor: isColorByLayer ? graphicStore?.extraContext?.getCurrentViewEditor()?.context.layerContext.currentLayerColor : currentColor
                                    backgroundColor: currentColor
                                }}
                                ></div>
                                <div className='text'>
                                    {isColorByLayer ? 'ByLayer' : currentColor}
                                </div>
                            </Fragment>
                        </span>

                        <div className='icon' style={{ userSelect: 'none' }}>
                            <DownArrowSvg width={6} height={3} />
                        </div>
                    </div>

                </Dropdown>

                <DragModal
                    title="选择颜色"
                    visible={paletteModalVisible}
                    width={550}
                    wrapClassName="color-select-modal"
                    onClose={() => setPaletteModalVisible(false)}>
                    <div className="color-select-modal-body">
                        <ColorPalette
                            onCancel={() => setPaletteModalVisible(false)}
                            onComplete={onComplete}></ColorPalette>
                    </div>

                </DragModal>
            </div>
        </Tooltip>

    )
}
