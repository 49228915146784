
export const WsConfig = {
    // TODO: build 部署时要修改ws地址端口
    address: 'wss://graphic.tncet.com',
    port: '9768',
    usePort: false,

}

// export const WsConfig = {
//     // TODO: build 部署时要修改ws地址端口
//     address: 'ws://localhost',
//     port: '9768',
//     usePort: true,

// }