import { Button, Divider, Form, Radio, Select, Space } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import './SelectModal.less';
import classnames from 'classnames';
import { graphicStore } from "@/commons/store/graphic-store";
import { ModelBase, SolidT } from "pytha";
import ColorChange from "../color/ColorChange";
import { Layer } from "@/commons/interface/layer";
import LineWidthChange from "./LineWidthChange";



const { Option } = Select;

interface IProps {
    onConfirm?: () => void
    onCancel?: () => void
    style?: CSSProperties
}

export default function SelectModal(props: IProps) {
    const [form] = Form.useForm();

    const editor = graphicStore.extraContext.getCurrentViewEditor();

    const ModelBaseType = {
        'LineT': '直线',
        'CircleT': '圆',
        'ArcT': '圆弧',
        'PlineT': '多段线',
        'DTextT': '文字',
        'RegionT': '面域',
        'CubeT': '立方体',
        'SolidT': '三维实体',
        'PatternFillT': '图案填充',
        'ExtrudeSurfaceT': '曲面（拉伸）',
        'ExtrudeSolidT': '三维实体',
        'DcalignedT': '对齐标注',
        'DclinearT': '线性标注',
        'BlockT': '块',
    }

    const PropertiesType = [
        {
            type: 0,
            name: '常规',
            properties: [
                { Chinese: '颜色', pr: 'color' },
                { Chinese: '图层', pr: 'layer' },
                { Chinese: '线宽', pr: 'lineWidth' },
            ],
        },
        {
            type: 1,
            name: '文字',
        },
        {
            type: 2,
            name: '几何图形',
        }
    ]

    const properties = PropertiesType.find(item => item.name === '常规').properties

    const [displayType, setDisplayType] = useState(null);
    const [objectType, setObjectType] = useState(null);
    const [displayObjectType, setDisplayObjectType] = useState(null);
    const [selectedLayerUuid, setSelectedLayerUuid] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedLineWidth, setSelectedLineWidth] = useState(null);
    const [selectByLayer, setSelectByLayer] = useState(true);
    const [selectLwByLayer, setSelectLwByLayer] = useState(null);
    const [selectedPr, setSelectedPr] = useState(null);
    const [allSelect, setAllSelect] = useState(false);

    useEffect(() => {
        const entities = editor.selectControl.getSelectedEntityList() ?? [];
        const allEntities = editor.getEntities() ?? [];
        if (allEntities.length === 0) return;
        let display = [];
        let displayObjectType = [];
        let allType = [];
        for (let object of allEntities) {
            let type = object.type;
            if ((object as SolidT)._geo) {
                type = 'SolidT';
            }
            if (!allType.some(item => item === type)) {
                allType.push(type);
            }
        }
        if (allType.length > 1) {
            allType.unshift('所有图元')
        }
        displayObjectType.push({ type: '整个图形', object_type: allType });
        display.push({ type: '整个图形', entities: allEntities });
        if (entities?.length > 0) {
            let types = [];
            for (let object of entities) {
                let type = object.type;
                if ((object as SolidT)._geo) {
                    type = 'SolidT';
                }
                if (!types.some(item => item === type)) {
                    types.push(type);
                }
            }
            if (types.length > 1) {
                types.unshift('所有图元')
            }
            display.push({ type: '当前选择', entities: entities });
            displayObjectType.push({ type: '当前选择', object_type: types });
            form.setFieldsValue({
                "apply_type": '当前选择',
                "object_type": types[0]
            })
            setDisplayType(display);
            setObjectType(displayObjectType);
            setDisplayObjectType(types);
            setSelectedPr(properties[0].pr);
            setSelectedLayerUuid(editor.context.layerContext.currentLayerUuid);
        } else {
            setDisplayType(display);
            setObjectType(displayObjectType);
            setDisplayObjectType(allType);
            setSelectedPr(properties[0].pr);
            setSelectedLayerUuid(editor.context.layerContext.currentLayerUuid);
            form.setFieldsValue({
                "apply_type": '整个图形',
                "object_type": allType[0],
            })
        }
    }, [])

    const changeSelectedApplayType = (e) => {
        let display = objectType.find(item => item.type === e).object_type
        setDisplayObjectType(display);
        form.setFieldsValue({
            object_type: display[0]
        })
    }
    const changeSelectedObjectType = (e) => {
        // console.log(e)
    }

    const onOperatorChange = (e) => {
        if (e === '全部选择') {
            setAllSelect(true);
        } else {
            setAllSelect(false);
        }
    }

    const onChange = (value: string) => {
        setSelectedLayerUuid(value);
    }

    const onConfirm = (values) => {
        let object_type = values.object_type;
        let method = values.method;
        let operator = values.operator;
        let selected = [];
        let selectedEntities: { [key: string]: ModelBase } = {};
        let display = displayType?.find(d => d.type === values.apply_type);
        let entities = display.entities;
        let allEntities = display.entities;

        if (object_type === '所有图元') {
            selected = entities;
        } else {
            if (object_type == 'SolidT') {
                selected = entities.filter(e => (e as SolidT)._geo);
            } else {
                selected = entities.filter(e => e.type === object_type);
            }
        }

        if (!allSelect) {
            if (selectedPr === 'color') {
                if (selectByLayer) {
                    if (operator === '=') {
                        selected = selected.filter(e => e.isColorByLayer);
                    }
                    if (operator === '<>') {
                        selected = selected.filter(e => !e.isColorByLayer);
                    }
                } else {
                    if (operator === '=') {
                        selected = selected.filter(e => `#${e.color.getHexString()}` === selectedColor);
                    }
                    if (operator === '<>') {
                        selected = selected.filter(e => `#${e.color.getHexString()}` != selectedColor);
                    }
                }
            } else if (selectedPr === 'layer') {
                if (operator === '=') {
                    selected = selected.filter(e => e.layerUuid === selectedLayerUuid)
                }
                if (operator === '<>') {
                    selected = selected.filter(e => e.layerUuid != selectedLayerUuid)
                }
            } else if (selectedPr === 'lineWidth') {
                if (selectLwByLayer) {
                    if (operator === '=') {
                        selected = selected.filter(e => e.isLineWidthByLayer);
                    }
                    if (operator === '<>') {
                        selected = selected.filter(e => !e.isLineWidthByLayer);
                    }
                } else {
                    if (operator === '=') {
                        selected = selected.filter(e => e.lineWidth === selectedLineWidth)
                    }
                    if (operator === '<>') {
                        selected = selected.filter(e => e.lineWidth != selectedLineWidth)
                    }
                }
            }
        }
        if (method === 'exclude') {
            selected = allEntities.filter(e => !selected.some(s => s.uuid === e.uuid))
        }

        // editor.listeners.signals.emptySelectedEntities.dispatch();
        for (let i = 0; i < selected.length; i++) {
            let entity = selected[i];
            selectedEntities[entity.uuid] = entity;
            entity.isSelected = true;
        }
        let oldSelectEntities = { ...editor.selectControl.getSelectedEntities() }
        for (let key in oldSelectEntities) {
            if (selectedEntities[key]) {
                continue
            } else {
                let entity = oldSelectEntities[key]
                entity.isSelected = false
            }
        }

        editor.selectControl.setSelectedEntities(selectedEntities);
        editor.listeners.signals.onSelectChanged.dispatch(selected);
        editor.listeners.signals.needRender.dispatch('redraw');
        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <div className="select-modal-container">
            {displayType?.length > 0 ?
                <Form onFinish={onConfirm} form={form} labelCol={{ span: 6 }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '30px' }}>
                        <Form.Item label="应用到(Y)" name="apply_type">
                            <Select style={{ width: '100%' }} onChange={changeSelectedApplayType} size="small">
                                {displayType?.map(item => (
                                    <Option value={item.type} key={item.type}>{item.type}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="对象类型(B)" name="object_type">
                            <Select style={{ width: '100%' }} onChange={changeSelectedObjectType} size="small">
                                {displayObjectType.map((item, key) => (
                                    <Option value={item} key={key}>{ModelBaseType[item] || item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="特性(P)">
                            <div className="pr-body">
                                <div className="pr-panel">
                                    {properties.map(p => (
                                        <div key={p.pr} className={classnames({
                                            "pr-item": true,
                                            'selected': p.pr === selectedPr,
                                            'allSelect': allSelect
                                        })} onClick={() => {
                                            if (allSelect) return
                                            setSelectedPr(p.pr)
                                        }}>
                                            <div className="pr-item-name">{p.Chinese}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Form.Item>

                        {selectedPr === 'color' && (
                            <Form.Item label="运算符(O)" name="operator" initialValue="=">
                                <Select style={{ width: '100%' }} size="small" onChange={onOperatorChange} >
                                    <Option value="=">{'= 等于'}</Option>
                                    <Option value="<>">{'<> 不等于'}</Option>
                                    <Option value=">">{'> 大于'}</Option>
                                    <Option value="<">{'< 小于'}</Option>
                                    <Option value="全部选择">全部选择</Option>
                                </Select>
                            </Form.Item>
                        )}
                        {(selectedPr === 'layer' || selectedPr === 'lineWidth') && (
                            <Form.Item label="运算符(O)" name="operator" initialValue="=">
                                <Select style={{ width: '100%' }} size="small" onChange={onOperatorChange} >
                                    <Option value="=">{'= 等于'}</Option>
                                    <Option value="<>">{'<> 不等于'}</Option>
                                    <Option value="全部选择">全部选择</Option>
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item label={allSelect ? '' : "值(V)"}>
                            {selectedPr === 'color' && !allSelect && (
                                <ColorChange
                                    editor={graphicStore?.extraContext?.getCurrentViewEditor()}
                                    selectColor={(color) => {
                                        setSelectedColor(color)
                                        setSelectByLayer(false)
                                    }}
                                    selectByLayer={() => { setSelectByLayer(true) }}
                                />
                            )}
                            {selectedPr === 'layer' && !allSelect && (
                                <Select
                                    style={{ width: '100%' }}
                                    size="small"
                                    value={selectedLayerUuid}
                                    onChange={onChange}
                                >
                                    {graphicStore?.context?.layerContext?.layers.map((layer: Layer, key) => (
                                        <Option value={layer.uuid} key={key}>
                                            {
                                                <div>
                                                    <div>{layer.name}</div>
                                                </div>
                                            }
                                        </Option>
                                    ))}
                                </Select>
                            )}
                            {selectedPr === 'lineWidth' && !allSelect && (
                                <LineWidthChange
                                    selectLineWidth={(lineWidth) => {
                                        setSelectedLineWidth(lineWidth)
                                        setSelectLwByLayer(false)
                                    }}
                                    selectByLayer={() => { setSelectLwByLayer(true) }}
                                />
                            )}
                        </Form.Item>
                    </div>
                    <Divider orientation="left" plain style={{ margin: '4px 0' }}>
                        如何应用：
                    </Divider>
                    <Form.Item name="method" initialValue="include">
                        <Radio.Group style={{ paddingLeft: '30px' }}>
                            <Space direction="vertical">
                                <Radio value="include">包括在新选择集中(I)</Radio>
                                <Radio value="exclude">排除在新选择集之外(E)</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <div className="manage-foot">
                        <span className="fill-remaining-space"></span>
                        <Space size="small">
                            <Button size="small" htmlType="submit">确定</Button>
                            <Button size="small" onClick={onCancel}>取消</Button>
                        </Space>
                    </div>
                </Form>
                :
                <div>
                    <div>当前的图形中没有任何对象。</div>
                    <div className="manage-foot">
                        <span className="fill-remaining-space"></span>
                        <Button size="small" onClick={onCancel}>关闭</Button>
                    </div>
                </div>
            }
        </div>
    )
}
