import classNames from "classnames";
import CustomLink from "./custom-link";
import { IsidebarItem } from "./sidebar";
import './sidebar-item.less'

interface Iprops {
    route?: IsidebarItem
    activedRouteId?: number,
    selected?: boolean,
    onActiveClick?: () => void
    toggleActiveRoute?: (routeId: number) => void,
    onContextMenu?: React.MouseEventHandler<HTMLDivElement>
}

export default function SidebarItem(props: Iprops) {

    return (
        <div className={classNames({
            'sidebar-item': true,
            'active': props.route?.id === props.activedRouteId,
            'selected': props.selected,
        })} onContextMenu={props.onContextMenu}>
            {props.route?.url && (
                <CustomLink to={props.route?.url} active={(active) => { if (active) { props.toggleActiveRoute(props.route?.id) } }} className="item-content" themecolor={props.route?.themeColor || null} >
                    <div style={{
                        width: '24px',
                        height: '100%'
                    }}>
                        {props.route?.icon}
                    </div>
                    <div className="item-name">
                        {props.route?.name}
                    </div>
                </CustomLink>
            )}
        </div>
    )
}