import { Select } from "antd";
import { Component } from "react";
import './LwSelect.less';
import { graphicStore } from "@/commons/store/graphic-store";
import { ReactComponent as DownArrowSvg } from '@/commons/icons/arrow/table-down-arrow.svg'
import { lineWidthList } from "../line-width/LwSelect";

const { Option } = Select;

interface IProps {
    selectLineWidth?: (lineWidth) => void,
    selectByLayer?: () => void,
}

export default class LineWidthChange extends Component<IProps> {
    state = {
        /// 当有东西选中时，用此变量显示选择器
        currentLineWidth: 0.25,
        isLineWidthByLayer: true,
        ///

        showProperty: true,

    }

    componentDidMount() {
        this.setState({
            currentLineWidth: graphicStore.context.layerContext.currentLineWidth,
            showProperty: true,
            isLineWidthByLayer: graphicStore.context.layerContext.isLineWidthByLayer,
        })
    }

    onChange = (lineWidth) => {
        if (lineWidth === 'ByLayer') {
            let lineWidth = graphicStore.context.layerContext.currentLineWidth;
            this.setState({
                showProperty: true,
                currentLineWidth: lineWidth,
                isLineWidthByLayer: true,
            })
            if (this.props.selectByLayer) {
                this.props.selectByLayer();
            }
        } else {
            this.setState({
                currentLineWidth: lineWidth,
                isLineWidthByLayer: false,
                showProperty: true,
            })
            this.props.selectLineWidth(lineWidth);
        }
    }

    render() {
        return (
            <div className="lw-select">
                <Select
                    dropdownStyle={{ backgroundColor: '#757575' }}
                    value={this.state.isLineWidthByLayer ? 'ByLayer' : this.state.currentLineWidth}
                    onChange={this.onChange}
                    suffixIcon={<DownArrowSvg width={6} height={3} />}
                >
                    <Option value="ByLayer" key="ByLayer">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: graphicStore.context.layerContext.currentLineWidth * 10 || 1 }}></div>
                            <div className="lw-select-name">ByLayer</div>
                        </div>
                    </Option>
                    {/* <Option value="ByBlock" key="ByBlock">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: this.props.layerStore.state.currentLayerLineWidth * 10 || 1 }}></div>
                            <div className="lw-select-name">ByBlock</div>
                        </div>
                    </Option> */}
                    {/* <Option value="Default" key="Default">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: 0.25 * 10 || 1 }}></div>
                            <div className="lw-select-name">默认</div>
                        </div>
                    </Option> */}
                    {lineWidthList.map((item, key) => (
                        <Option value={+item} key={key}>
                            <div className="lw-select-option" style={{ width: '100%' }}>
                                <div className="lw-select-box" style={{ width: "40%", height: item * graphicStore.context.renderContext.lineWidthDisplayScale || 1, background: '#FFF' }}></div>
                                <div className="lw-select-name">{item.toFixed(2) + '毫米'}</div>
                            </div>
                        </Option>
                    ))}
                </Select>
            </div>
        )
    }
}