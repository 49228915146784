
import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";
import { BasicControl, TnEngineExtraContext, ViewEditor, Listeners } from "pytha";

export class PropertiesControl extends BasicControl {

    isSingleUse = true;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.extraContext.dymodeContext.reset();
        this.editor = editor;
        this.editor.listeners.signals.onDymodeVisible.dispatch(false);
        this.listeners = listeners;
        this.listeners.getSignal(EXTRA_SINGAL.onPropertiesShow).dispatch()
    }
    dispose(): void {

    }

}