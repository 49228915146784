import { SystemContext } from "@/App";
import PropertiesSetting from "@/commons/components/properties/PropertiesSetting";
import { SystemStatus } from "@/commons/context/system-store";
import { graphicStore } from "@/commons/store/graphic-store";
import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";
import { useContext, useEffect, useState } from "react";


export default function SideBar() {
    const [sidePanel, setSidePanel] = useState<string>("");

    const systemContext = useContext<SystemStatus>(SystemContext);

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners?.getSignal(EXTRA_SINGAL.onPropertiesShow)) {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onPropertiesShow).add(showProperty)
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onPropertiesShow).add(showProperty)
            }, 5000)
        }
        return (() => {
            if (graphicStore?.extraContext?.listeners?.getSignal(EXTRA_SINGAL.onPropertiesShow))
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onPropertiesShow).remove(showProperty)
        })
    }, [])

    useEffect(() => {
        // 重新设置图形区大小
        graphicStore?.extraContext?.listeners?.signals.windowResize.dispatch();
    }, [sidePanel])

    useEffect(() => {
        setSidePanel(systemContext.selectedPanel);
    }, [systemContext?.selectedPanel]);

    const showProperty = () => {
        systemContext.setSelectedPanel("PR")
    }

    return (
        <>
            <div style={{
                height: '100%',
                // width: "300px",
            }}>
                {sidePanel === "PR" && <PropertiesSetting />}
            </div>
        </>

    )
}
