import { useState } from "react";

export interface NavigationStatus {
    currentProjectId: number
    setCurrentProjectId: React.Dispatch<React.SetStateAction<number>>
    isDemoUser: boolean
    setIsDemoUser: React.Dispatch<React.SetStateAction<boolean>>
}

export function useNavigationService() {
    const [currentProjectId, setCurrentProjectId] = useState<number>();
    const [isDemoUser, setIsDemoUser] = useState<boolean>();

    return {
        currentProjectId,
        setCurrentProjectId,

        isDemoUser,
        setIsDemoUser,
    } as NavigationStatus
}