import { Command, BlockT, TnEngineExtraContext, ViewEditor } from "pytha";

export class InsertBlockCommand extends Command {

    block: BlockT;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, block: BlockT) {
        super();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.editor = editor;    
        this.block = block;
    }

    execute = () => {
        if (this.block.getEntities().length === 0) return;
        this.editor.addObject(this.block);
        this.extraContext.listeners.signals.needRender.dispatch('redraw');
        this.extraContext.apiContext.createPrimitive(this.block.toJson());
    }

    undo(): void {
        if (this.block.getEntities().length === 0) return;
        this.editor.removeObject(this.block);
        this.extraContext.listeners.signals.needRender.dispatch('redraw');
        this.extraContext.apiContext.deletePrimitivesInGraphic([this.block.uuid]);
    }
}