import { Block } from "@/commons/interface/block";
import localStore from "@/commons/store/local-store";
import { graphicAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function createBlock(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/blocks`, data, {
        params: {
            ...params,
            graphicId: localStore.graphicId,
        }
    })
}

export function createBlockMulti(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/blocks/multi`, data, {
        params: {
            ...params,
            graphicId: localStore.graphicId,
        }
    })
}

export function findBlocksInGraphic(graphicId: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphics/${graphicId}/blocks`, {
        params: {
            ...params,
        }
    })
}

export function findOneBlockInGraphic(graphicId: string, name: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphics/${graphicId}/blocks/name`, {
        params: {
            name: name
        }
    })
}

export function deleteBlocksInGraphic(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    const modelId = localStore.modelId;
    return graphicAxios.post(`/graphics/${modelId}/blocks/delete`, uuids, {
        params: {
            ...params,
        }
    })
}

export function updateBlock(uuid: string, block: Block, params?: {}): Promise<AxiosResponse<any>> {
    const projectId = localStore.projectId;
    const modelId = localStore.modelId;

    return graphicAxios.put(`/projects/${projectId}/models/${modelId}/blocks/${uuid}`, block, {
        params: {
            ...params,
        }
    })
}

export function getBlockInfo(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    const projectId = localStore.projectId;

    return graphicAxios.get(`/projects/${projectId}/blocks/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function getBlocksByProjectId(params?: {}): Promise<AxiosResponse<any>> {
    const projectId = localStore.projectId;
    return graphicAxios.get(`/projects/${projectId}/blocks`, {
        params: {
            ...params,
        }
    })
}

export function getBlocksByUuids(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    const projectId = localStore.projectId;
    return graphicAxios.post(`/projects/${projectId}/blocks/uuids`, uuids, {
        params: {
            ...params,
        }
    })
}

export function deleteBlockMulti(blockIds: string[], params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/blocks/delete-multi`, blockIds, {
        params: {
            ...params,
        }
    })
}