
import { BasicControl, BlockT, CONTROL_TYPE, CURSOR_TYPE, DYMODE_MODE, Listeners, MOUSE, ModelBase, Point, TnEngineContext, TnEngineExtraContext, ViewEditor, dymode } from "pytha";
import { Vector3 } from "three";
import { AddBlockCommand } from "../commands/AddBlockCommand";
import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";

export class AddBlockControl extends BasicControl {

    id = CONTROL_TYPE.ADD_BLOCK;

    point_down: Vector3 = new Vector3();

    capturePoint: Vector3;

    step: number = 1;

    selectEntities: ModelBase[];

    basePoint: Point = new Point();

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();

        this.editor = editor;
        this.listeners = listeners;

        this.listeners.signals.onPointCapture.add(this.onPointCapture);
        this.extraContext.dymodeContext.reset();
        this.editor.listeners.signals.onDymodeVisible.dispatch(false);
        this.listeners.signals.onKeyMouseConfirm.add(this.onKeyMouseConfirm);
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
        this.editor.listeners.signals.onSelectChanged.add(this.onSelectChanged);
        this.editor.clampControl.enabled = false;
        this.editor.clampControl.hidden = true;
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake).dispatch();
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectPoint).add(this.selectPoint);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectEntity).add(this.selectEntity);
        this.editor.listeners.getSignal(EXTRA_SINGAL.onBlockDefine).add(this.createBlock);
        this.editor.listeners.getSignal(EXTRA_SINGAL.onBlockDefineOff).add(this.cancle);
        this.selectEntities = this.editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock);
        console.log('AddBlockControl added')
    }

    dispose(): void {
        this.editor.listeners.signals.onSelectChanged.remove(this.onSelectChanged);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirm);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectPoint).remove(this.selectPoint);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectEntity).remove(this.selectEntity);
        this.editor.listeners.getSignal(EXTRA_SINGAL.onBlockDefine).remove(this.createBlock);
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.editor.selectControl.setEnabled(true);
        this.editor.clampControl.enabled = true;
        this.editor.clampControl.hidden = false;
        console.log('AddBlockControl removed')
    }


    onPointCapture = (point) => {
        this.capturePoint = point;
    }

    selectPoint = () => {
        const domElement = this.editor.renderer?.domElement;
        domElement?.focus();
        this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
        this.extraContext.dymodeContext.enabled = true;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.POINT);
        this.extraContext.dymodeContext.setDymodeTip('指定基点:');
        this.extraContext.stmodeContext.setStmode('指定基点', []);
        this.editor.selectControl.setEnabled(false);
        this.listeners.signals.needCapture.dispatch();
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
    }

    selectEntity = () => {
        this.extraContext.stmodeContext.setStmode('选择对象', []);
        this.editor.selectControl.setEnabled(true);
        this.listeners.signals.noNeedCapture.dispatch();
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.PICK);
    }

    onSelectChanged = () => {
        this.selectEntities = this.editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock); 
    }

    createBlock = (name, x: number, y: number, z: number) => {
        if (this.selectEntities?.includes) {
            let entities = [];
            this.selectEntities.forEach(entity => entities.push(entity.clone()));
            let block = new BlockT(new Point(x, y, z), entities, name, new Vector3(0, 0, 1), new Vector3(1, 0, 0));
            block.layerUuid = this.context.layerContext.currentLayerUuid;
            this.editor.history.execute(new AddBlockCommand(block, this.selectEntities));
        } else {
            this.extraContext.stmodeContext.pushLog('尚未选择任何对象');
        }
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    cancle = () => {
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    onPointerDown = (event) => {
        if (event.button !== MOUSE.LEFT) return;
        this.point_down.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        if (this.capturePoint) {
            this.point_down.copy(this.capturePoint);
        }
        this.basePoint.copy(this.point_down);
        this.extraContext.dymodeContext.enabled = false;
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint).dispatch(this.point_down.x, this.point_down.y, this.point_down.z);
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.onPointCapture.remove(this.onPointCapture);
        this.listeners.signals.noNeedCapture.dispatch();
    }

    onKeyMouseConfirm = () => {
        this.selectEntities = this.editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock);
        this.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake).dispatch();
    }

    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this.basePoint.copy(this.point_down);
            this.extraContext.dymodeContext.enabled = false;
            this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
            this.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint).dispatch(this.point_down.x, this.point_down.y, this.point_down.z);
            this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
            this.listeners.signals.onPointCapture.remove(this.onPointCapture);
            this.listeners.signals.noNeedCapture.dispatch();
        }
    }
}