import { StdResponse, graphicAxios } from "@/config/axios";

export function checkFileExists(md5: string, params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.get(`/files/md5/${md5}`, {
        params: params,
    })
}

export function getFileUploadUrl(objectName: string, params?: {}): Promise<StdResponse<any>> {
    return graphicAxios.get(`/objects/upload/url`, {
        params: {
            objectName: `${objectName}`,
            ...params,
        }
    })
}

