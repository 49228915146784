import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";
import { BasicControl, TnEngineContext, TnEngineExtraContext, ViewEditor, Listeners } from "pytha";


export class AwakeTextStyleModalControl extends BasicControl {

    isSingleUse = true;
   
    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.extraContext.dymodeContext.reset();
        editor.listeners.signals.onDymodeVisible.dispatch(false);
        listeners.getSignal(EXTRA_SINGAL.onTextStyleManageAwake).dispatch();
    }
    dispose(): void {
        
    }

}