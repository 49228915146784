import { Button, Card, Input, message, Radio, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as blockSelectEntity } from '@/commons/icons/block/blockSelectEntity.svg';
import { ReactComponent as blockSelectPoint } from '@/commons/icons/block/blockSelectPoint.svg';
import { ReactComponent as quick } from '@/commons/icons/block/quick.svg';
import Icon from '@ant-design/icons'
import './BlockDefine.less';
import { graphicStore } from "@/commons/store/graphic-store";
import { DragModal } from "tncet-common";
import { findOneBlockInGraphic } from "@/api/geometry/block";
import localStore from "@/commons/store/local-store";
import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";


export default function BlockDefine() {
    const renameRef = useRef<any>()
    const factorInputRef = useRef<any>()

    const [blockDefineVisible, setBlockDefineVisible] = useState<boolean>(false)
    const [blockName, setBlockName] = useState<string>("")
    const [basePointX, setBasePointX] = useState<number>(0.0)
    const [basePointY, setBasePointY] = useState<number>(0.0)
    const [basePointZ, setBasePointZ] = useState<number>(0.0)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            if(!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake)){
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onBlockDefineAwake)
            }
            if(!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint)){
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onBlockDefinePoint)
            }
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake).add(awakeBlockDefine)
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint).add(getBlockDefinePoint)
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake).add(awakeBlockDefine)
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint).add(getBlockDefinePoint)
            }, 5000)
        }

        return (() => {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineAwake).remove(awakeBlockDefine)
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefinePoint).remove(getBlockDefinePoint)
        })
    }, [])

    const awakeBlockDefine = () => {
        setBlockDefineVisible(true)
    }

    const selectPoint = () => {
        setBlockDefineVisible(false)
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectPoint).dispatch();
    }

    const getBlockDefinePoint = (x: number, y: number, z: number) => {
        setBlockDefineVisible(true)
        setBasePointX(x)
        setBasePointY(y)
        setBasePointZ(z)

    }

    const selectEntity = () => {
        setBlockDefineVisible(false)
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineSelectEntity).dispatch();
    }

    const quickSelectEntity = () => {
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onQuickSelectAwake).dispatch();
    }

    const apply = () => {
        if (blockName.match(/^\s*$/)) {
            message.warning('此名称的长度必须至少为一个字符。');
            return;
        }
        findOneBlockInGraphic(localStore.graphicId, blockName.trim()).then(res => {
            if (res.data) {
                // 已存在该名称的块
                message.warning(`名称` + blockName.trim() + `已在使用。`);
                return;
            }
            setBlockDefineVisible(false)
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefine).dispatch(blockName.trim(), basePointX, basePointY, basePointZ);
        })
    }

    const cancle = () => {
        setBlockDefineVisible(false)
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockDefineOff).dispatch();
    }

    return (
        <DragModal
            visible={blockDefineVisible}
            destroyOnClose
            centered={false}
            width={600}
            onClose={() => cancle()}
            title="块定义">
            <div className="blockDefine">
                <div className="blockDefine-header">
                    <span>名称: </span>
                    <Input
                        size="small"
                        style={{ border: '1px solid #898989', width: '200px', backgroundColor: '#ffffff' }}
                        defaultValue={blockName}
                        ref={renameRef}
                        onBlur={(e) => { setBlockName(e.target.value) }}
                        //@ts-ignore
                        onPressEnter={(e) => setState({ blockName: e.target.value })} />
                </div>
                <div className="blockDefine-body">
                    <Space direction="horizontal" size="small">
                        <Card title="基点" size="small" style={{ width: 290, height: 220, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <Space direction="vertical" size="middle">
                                <div className="blockDefine-point">
                                    <Button type="link" icon={<Icon component={blockSelectPoint} />} size="small" onClick={() => selectPoint()}></Button>
                                    <span>拾取点</span>
                                </div>
                                <div className="blockDefine-pointX">
                                    <span>X: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        defaultValue={basePointX}
                                        onBlur={(e) => {
                                            setBasePointX(+e.target.value)
                                        }}
                                        onPressEnter={(e) => {
                                            setBasePointX(+e.target)
                                        }} />
                                </div>
                                <div className="blockDefine-pointY">
                                    <span>Y: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        defaultValue={basePointY}
                                        onBlur={(e) => { setBasePointY(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => setState({ basePointY: e.target.value })} />
                                </div>
                                <div className="blockDefine-pointZ">
                                    <span>Z: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        defaultValue={basePointZ}
                                        onBlur={(e) => { setBasePointZ(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => setState({ basePointZ: e.target.value })} />
                                </div>
                            </Space>
                        </Card>
                        <Card title="对象" size="small" style={{ width: 290, height: 220, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <Space direction="vertical" size="middle">
                                <div className="blockDefine-entity">
                                    <Button type="link" icon={<Icon component={blockSelectEntity} />} size="small" onClick={() => selectEntity()}></Button>
                                    <span>选择对象</span>
                                </div>
                                <div className="blockDefine-quick">
                                    <Button type="link" icon={<Icon component={quick} />} size="small" onClick={() => quickSelectEntity()}></Button>
                                    <span>快速选择</span>
                                </div>
                                <div className="blockDefine-radio">
                                    <Radio.Group value={1} size="small">
                                        <Radio value={1}>转换为块</Radio>
                                    </Radio.Group>
                                </div>

                            </Space>
                        </Card>
                    </Space>
                </div>
                <div className="blockDefine-foot">
                    <Space size="small">
                        <Button size="small" style={{ width: '60px' }} onClick={apply}>确定</Button>
                        <Button size="small" style={{ width: '60px' }} onClick={cancle}>取消</Button>
                    </Space>
                </div>
            </div>
        </DragModal >

    )
}
