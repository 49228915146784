import { Button, Card, Input, message, Select, Space, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as blockSelectPoint } from '@/commons/icons/block/blockSelectPoint.svg';
import Icon from '@ant-design/icons'
import './BlockInsert.less';

import { AmbientLight, Color, Object3D, OrthographicCamera, Scene, Vector3, WebGLRenderer } from "three";
import { BlockT, Point } from "pytha";
import { graphicStore } from "@/commons/store/graphic-store";
import { createBlock, findBlocksInGraphic } from "@/api/geometry/block";
import localStore from "@/commons/store/local-store";
import { DragModal } from "tncet-common";
import { EXTRA_SINGAL } from "@/tool/enums/extra-singal";


export default function BlockInsert() {
    const factorInputRef = useRef<any>()

    const renderer = useRef<WebGLRenderer>();
    const camera = useRef<OrthographicCamera>();
    const scene = useRef<Object3D>();
    const blocks = useRef<BlockT[]>();
    const show = useRef<HTMLElement>();
    const currentBlock = useRef<BlockT>();
    const target = useRef<Vector3>(new Vector3(0, 0, 0))
    const [blockInsertVisible, setBlockInsertVisible] = useState<boolean>(false)
    const [currentBlockName, setCurrentBlockName] = useState<string>("")
    const [basePointX, setBasePointX] = useState<number>(0.00)
    const [basePointY, setBasePointY] = useState<number>(0.00)
    const [basePointZ, setBasePointZ] = useState<number>(0.00)
    const [options, setOptions] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [scaleX, setScaleX] = useState<number>(1.00)
    const [scaleY, setScaleY] = useState<number>(1.00)
    const [scaleZ, setScaleZ] = useState<number>(1.00)
    const [angle, setAngle] = useState<number>(0.00)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            if(!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertAwake)){
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onBlockInsertAwake)
            }
            if(!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint)){
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onBlockInsertPoint)
            }
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertAwake).add(awakeBlockInsert);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint).add(getBlockInsertPoint);
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertAwake).add(awakeBlockInsert);
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint).add(getBlockInsertPoint);
            }, 5000)
        }
        return (() => {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertAwake).remove(awakeBlockInsert);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertPoint).remove(getBlockInsertPoint);
        })
    }, [])

    useEffect(() => {
        if (currentBlock.current) {
            scene.current.remove(currentBlock.current.render());
        }
        let block = blocks.current?.find(item => item.name === currentBlockName)
        if (block) {
            currentBlock.current = block
            scene.current.add(block.render())
            showAllEntities(block.getEntities())
            renderer.current.render(scene.current, camera.current);
        }

    }, [currentBlockName])

    const createRender = () => {
        show.current = document.getElementById('block-show-panel');
        const showRenderer = new WebGLRenderer();
        showRenderer.setClearColor('#000000', 1.0);
        showRenderer.autoClear = false;
        showRenderer.setSize(120, 140);
        show.current.appendChild(showRenderer.domElement);
        renderer.current = showRenderer;
    }

    const createCamera = () => {
        const midcamera = new OrthographicCamera(-1000, 1000, 1000, -1000, 0, 1000);
        midcamera.position.set(0, 0, 500);
        midcamera.lookAt(0, 0, 0);
        camera.current = midcamera;
    }

    const createScene = () => {
        const midScene = new Scene();
        midScene.background = new Color(0x000000);
        const ambient = new AmbientLight(0xffffff);
        midScene.add(ambient);

        let block = blocks.current.find(item => item.name === currentBlockName);

        if (block) {
            currentBlock.current = block;
            midScene.add(block.render());
            showAllEntities(block.getEntities());
        }

        scene.current = midScene;
        target.current = new Vector3(0, 0, 0);
        renderer.current.render(scene.current, camera.current);
    }

    const showAllEntities = (entities) => {
        try {
            let box = computeEntityBoundingBox(entities);
            let midcamera = camera.current;
            box.min.project(midcamera);
            box.max.project(midcamera);
            showEntitiesInRect(box.min.x, box.min.y, box.max.x, box.max.y, 1.3);
        } catch (error) {
            console.warn(error);
        }
    }

    const computeEntityBoundingBox = (entities) => {
        let minX = + Infinity;
        let minY = + Infinity;
        let minZ = + Infinity;

        let maxX = - Infinity;
        let maxY = - Infinity;
        let maxZ = - Infinity;
        let counter = 0;
        for (let key of Object.keys(entities)) {
            let entity = entities[key];
            if (entity == null) continue;
            let points = entity.getPoints();
            points.forEach(point => {
                if (point.x < minX) minX = point.x;
                if (point.y < minY) minY = point.y;
                if (point.z < minZ) minZ = point.z;

                if (point.x > maxX) maxX = point.x;
                if (point.y > maxY) maxY = point.y;
                if (point.z > maxZ) maxZ = point.z;
                counter++;
            })
        }

        if (Object.keys(entities).length === 0 || counter === 0) {
            return {
                min: new Vector3(-100, -100, 0),
                max: new Vector3(100, 100, 0)
            }
        }
        // TODO: points为空
        if (minX === maxX) {
            minX -= 100;
            maxX += 100;
        }
        if (minY === maxY) {
            minY -= 100;
            maxY += 100;
        }
        if (minZ === maxZ) {
            minZ -= 100;
            maxZ += 100;
        }
        let res = {
            min: new Vector3(minX, minY, minZ),
            max: new Vector3(maxX, maxY, maxZ),
        }
        return res;
    }

    const showEntitiesInRect = (x1, y1, x2, y2, alpha = 1) => {
        let scale1 = Math.abs(y1 - y2) / 2;
        let scale2 = Math.abs(x1 - x2) / 2;
        // max
        let scale = Math.max(scale1, scale2) * alpha;
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let midcamera = camera.current;
        let midTarget = graphicStore.context.ucsContext.unprojectCursorPoint(x, y, midcamera);
        let lastTarget = midTarget;
        let offset = midcamera.position.clone().sub(lastTarget);
        const position = midcamera.position;
        position.copy(midTarget).add(offset);
        midcamera.lookAt(midTarget)
        // scale 框和当前边界的比例
        midcamera.left = midcamera.left * scale;
        midcamera.right = midcamera.right * scale;
        midcamera.top = midcamera.top * scale;
        midcamera.bottom = midcamera.bottom * scale;
        midcamera.updateProjectionMatrix();
        target.current = midTarget;
    }

    const awakeBlockInsert = () => {
        setBlockInsertVisible(true)
        findBlocksInGraphic(localStore.graphicId).then(res => {
            let jsonList: [] = res.data || [];
            let options = [];
            let blocksName = [];
            
            blocks.current = jsonList.map((json: any) => {
                let entity = new BlockT();
                entity.fromJson(json);
                if (blocksName.indexOf(entity.name) === -1) {
                    entity.render();
                    options.push({ value: entity.name });
                    blocksName.push(entity.name);
                    return entity;
                }
                return null
            })
            blocks.current = blocks.current.filter(item => item != undefined);
            let name = blocks.current.length == 0 ? "" : blocks.current[0].name;
            setLoading(false)
            setCurrentBlockName(name)
            setOptions(options)

            createRender();
            createCamera();
            createScene();
        })
    }

    const getBlocksAfterImport = (name: string) => {
        findBlocksInGraphic(localStore.graphicId).then(res => {
            let jsonList: [] = res.data || [];
            let options = [];
            let blocksName = [];
            blocks.current = jsonList.map((json: any) => {
                let entity = new BlockT();
                entity.fromJson(json);
                if (blocksName.indexOf(entity.name) === -1) {
                    entity.render();
                    options.push({ value: entity.name });
                    blocksName.push(entity.name);
                    return entity;
                }
                return null
            })
            blocks.current = blocks.current.filter(item => item != undefined);
            setLoading(false)
            setCurrentBlockName(name)
            setOptions(options)

            scene.current.remove(currentBlock?.current?.render());
            let block = blocks.current.find(item => item.name === name)

            currentBlock.current = block;
            scene.current.add(block.render());
            showAllEntities(block.getEntities());


            renderer.current.render(scene.current, camera.current);
        })
    }

    const selectPoint = () => {
        setBlockInsertVisible(false)
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertSelectPoint).dispatch();
    }

    const getBlockInsertPoint = (x: number, y: number, z: number) => {
        setBasePointX(x)
        setBasePointY(y)
        setBasePointZ(z)
        setBlockInsertVisible(true)
        wait();
    }

    const wait = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                createRender();
                createCamera();
                createScene();
                resolve(1)
            }, 500)
        })
    }

    const handleChange = (value) => {
        setCurrentBlockName(value)
    }

    const onExportBlock = () => {
        //导出json文件
        let data = currentBlock.current.toJson();
        let json = JSON.stringify(data, undefined, 4);
        const blob = new Blob([json], { type: 'text/json' });
        let href = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = currentBlockName + '.txt';
        link.href = href;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
    }

    const onImportBlock = () => {
        let input = document.getElementById('import');
        input.click();
    }

    const onFileImport = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = onSuccess;
        reader.onabort = abortUpload;
        reader.onerror = errorHandler;
        reader.readAsText(file, "UTF-8");
    }

    const onSuccess = (event) => {
        let fileReader = event.target;
        if (fileReader.error) return console.log("error onloadend!");
        let json = JSON.parse(fileReader.result);
        json.graphicUuid = localStore.graphicId;
        json.layerUuid = graphicStore.context.layerContext.currentLayerUuid;
        json.entities.forEach(json => {
            json.layerUuid = graphicStore.context.layerContext.currentLayerUuid;
            json.graphicUuid = localStore.graphicId;
        })
        createBlock(json).then(res => {
            if (options.indexOf(json.name) === -1) {
                getBlocksAfterImport(json.name);
                message.success("导入成功！");
            } else {
                message.info("已存在同名块!");
            }
        })
    }

    const abortUpload = () => {
        console.log('Aborted read');
    }

    const errorHandler = (event) => {
        switch (event.target.error.code) {
            case event.target.error.NOT_FOUND_ERR:
                alert('File Not Found!');
                break;
            case event.target.error.NOT_READABLE_ERR:
                alert('File is not readable');
                break;
            case event.target.error.ABORT_ERR:
                break; // noop
            default:
                alert('An error occurred reading this file.');
        }
    }

    const apply = () => {
        setBlockInsertVisible(false)
        let block = blocks.current.find(item => item.name === currentBlockName);
        let blockJson = block.toJson();
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsert).dispatch(
            blockJson,
            new Point(basePointX, basePointY, basePointZ),
            angle,
            0, 0, 0
        );
    }

    const cancle = () => {
        setBlockInsertVisible(false)
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onBlockInsertOff).dispatch();
    }

    return (
        <DragModal
            visible={blockInsertVisible}
            destroyOnClose
            centered={false}
            width={640}
            onClose={() => cancle()}
            title="插入">
            <div className="blockInsert">
                <Spin spinning={loading}>
                    <div className="blockInsert-header">
                        <span>名称: </span>
                        <Select
                            size="small"
                            style={{ width: '200px', backgroundColor: '#ffffff' }}
                            value={currentBlockName}
                            options={options}
                            onChange={handleChange}
                        >
                        </Select>
                        <Button onClick={onExportBlock} size="small">导出块</Button>
                        <Button onClick={onImportBlock} size="small">导入块</Button>
                        <input type="file" accept=".txt" id="import" onChange={onFileImport} style={{ display: 'none' }} />
                    </div>
                </Spin>
                <div className="blockInsert-body">
                    <Space direction="horizontal" size="small" style={{ padding: '3px' }}>
                        <Card title="插入点" size="small" style={{ width: 150, height: 200, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <Space direction="vertical" size="middle">
                                <div className="blockInsert-point">
                                    <Button type="link" icon={<Icon component={blockSelectPoint} />} size="small" onClick={() => selectPoint()}></Button>
                                    <span>屏幕上指定点</span>
                                </div>
                                <div className="blockInsert-pointX">
                                    <span>X: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={basePointX}
                                        disabled
                                        onBlur={(e) => { setBasePointX(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setBasePointX(+e.target.value) }} />
                                </div>
                                <div className="blockInsert-pointY">
                                    <span>Y: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={basePointY}
                                        disabled
                                        onBlur={(e) => { setBasePointY(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setBasePointY(+e.target.value) }} />
                                </div>
                                <div className="blockInsert-pointZ">
                                    <span>Z: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={basePointZ}
                                        disabled
                                        onBlur={(e) => { setBasePointZ(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setBasePointZ(+e.target.value) }} />
                                </div>
                            </Space>
                        </Card>
                        <Card title="比例" size="small" style={{ width: 150, height: 200, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <Space direction="vertical" size="middle">
                                <div className="blockInsert-scaleX">
                                    <span>X: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={scaleX}
                                        onBlur={(e) => { setScaleX(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setScaleX(+e.target.value) }}
                                        disabled />
                                </div>
                                <div className="blockInsert-scaleY">
                                    <span>Y: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={scaleY}
                                        onBlur={(e) => { setScaleY(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setScaleY(+e.target.value) }}
                                        disabled />
                                </div>
                                <div className="blockInsert-scaleZ">
                                    <span>Z: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '100px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={scaleZ}
                                        onBlur={(e) => { setScaleZ(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setScaleZ(+e.target.value) }}
                                        disabled />
                                </div>
                            </Space>
                        </Card>
                        <Card title="旋转" size="small" style={{ width: 150, height: 200, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <Space direction="vertical" size="middle">
                                <div className="blockInsert-rotate">
                                    <span>角度: </span>
                                    <Input
                                        size="small"
                                        style={{ border: '1px solid #898989', width: '80px', backgroundColor: '#ffffff' }}
                                        ref={factorInputRef}
                                        value={angle}
                                        onBlur={(e) => { setAngle(+e.target.value) }}
                                        //@ts-ignore
                                        onPressEnter={(e) => { setAngle(+e.target.value) }}
                                        disabled />
                                </div>
                            </Space>
                        </Card>
                        <Card title="样式预览" size="small" style={{ width: 150, height: 200, backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} headStyle={{ height: '10px', borderBlockColor: '#dcdcdc' }}>
                            <div id="block-show-panel" className="block-show-panel" style={{ width: '150px' }}></div>
                        </Card>
                    </Space>
                </div>
                <div className="blockInsert-foot">
                    <Space size="small">
                        <Button size="small" style={{ width: '60px' }} onClick={apply}>确定</Button>
                        <Button size="small" style={{ width: '60px' }} onClick={cancle}>取消</Button>
                    </Space>
                </div>
            </div>
        </DragModal >

    )
}

