import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './LwSetting.less';
import { graphicStore } from '@/commons/store/graphic-store';
import { ModelBase, ToggleDrawLineWidthCommand, ToggleEntityLineWidthCommand } from 'pytha';

const lineWidthList = [
    0.00,
    0.05,
    0.09,
    0.13,
    0.15,
    0.18,
    0.20,
    0.25,
    0.30,
    0.35,
    0.40,
    0.50,
    0.53,
    0.60,
    0.70,
    0.80,
    0.90,
    1.00,
    1.06,
    1.20,
    1.40,
    1.58,
    2.00,
    2.11,
]

const { Option } = Select;
export default function LwSetting() {
    const ref = useRef<any>();

    const [currentLineWidth, setCurrentLineWidth] = useState<number>(0.25)
    const [isLineWidthByLayer, setIsLineWidthByLayer] = useState<boolean>(true)
    const [hasSelected, setHasSelected] = useState<boolean>(false)
    const [showProperty, setShowProperty] = useState<boolean>(true)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            graphicStore.extraContext.listeners.signals.onFilterChanged.add(onFilterChanged)

        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.signals.onFilterChanged.add(onFilterChanged)
            }, 5000)
        }
        return () => {
            graphicStore.extraContext.listeners.signals.onFilterChanged.remove(onFilterChanged)
        }
    }, [])

    const onFilterChanged = (entities: ModelBase[]) => {
        let selects = entities;
        if (selects.length === 0) {
            // 没有东西选中
            setCurrentLineWidth(graphicStore?.context?.layerContext?.currentLayerLineWidth)
            setHasSelected(false)
            setShowProperty(true)
            setIsLineWidthByLayer(graphicStore?.context?.layerContext.isLineWidthByLayer)
            return;
        }
        let isLineWidthByLayer;
        for (let selected of selects) {
            if (isLineWidthByLayer == null) {
                isLineWidthByLayer = selected.isLineWidthByLayer;
            } else if (isLineWidthByLayer !== selected.isLineWidthByLayer) {
                isLineWidthByLayer = null;
                setShowProperty(false)
                setHasSelected(true)
                return;
            }
        }
        let lineWidth;
        if (!!isLineWidthByLayer) {
            // 如果选中的对象颜色都是随层
            lineWidth = graphicStore?.context?.layerContext.isLineWidthByLayer;
        } else {
            for (let selected of selects) {
                if (lineWidth == null) {
                    lineWidth = selected.lineWidth;
                } else if (lineWidth !== selected.lineWidth) {
                    lineWidth = null;
                    setShowProperty(false)
                    setHasSelected(true)
                    return;
                }
            }
        }
        setCurrentLineWidth(lineWidth)
        setIsLineWidthByLayer(isLineWidthByLayer)
        setHasSelected(true)
        setShowProperty(true)
    }

    const onChange = (lineWidth) => {
        let editor = graphicStore?.extraContext?.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        if (lineWidth === 'ByLayer') {
            let lineWidth = graphicStore?.context?.layerContext?.currentLayerLineWidth;
            if (selects.length === 0) {
                editor.history.execute(new ToggleDrawLineWidthCommand({
                    lineWidth: lineWidth,
                    isLineWidthByLayer: true,
                }, {
                    lineWidth: graphicStore?.context?.layerContext.currentLineWidth,
                    isLineWidthByLayer: graphicStore?.context?.layerContext.isLineWidthByLayer,
                }))
            } else {
                editor.history.execute(new ToggleEntityLineWidthCommand(selects, lineWidth, true));
            }
            graphicStore.extraContext.listeners.signals.onLineWidthChanged.dispatch();
            setShowProperty(true)
            setCurrentLineWidth(lineWidth)
            setIsLineWidthByLayer(true)
            return;
        }
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawLineWidthCommand({
                lineWidth: lineWidth,
                isLineWidthByLayer: false,
            }, {
                lineWidth: graphicStore?.context?.layerContext.currentLineWidth,
                isLineWidthByLayer: graphicStore?.context?.layerContext.isLineWidthByLayer,
            }))

        } else {
            editor.history.execute(new ToggleEntityLineWidthCommand(selects, lineWidth, false));
        }
        graphicStore?.extraContext.listeners.signals.onLineWidthChanged.dispatch();
        setCurrentLineWidth(lineWidth)
        setIsLineWidthByLayer(false)
        setShowProperty(true)
        ref.current?.blur();
    }

    return (
        <div className="lw-setting">
            <Select
                style={{ width: '160px' }}
                dropdownStyle={{ backgroundColor: '#757575' }}
                size="small"
                value={(!hasSelected) ? (graphicStore?.context?.layerContext.isLineWidthByLayer ? 'ByLayer' : graphicStore?.context?.layerContext.currentLineWidth) : (showProperty ? (isLineWidthByLayer ? 'ByLayer' : currentLineWidth) : null)}
                onChange={onChange}
                ref={ref}>
                <Option value="ByLayer" key="ByLayer">
                    <div className="lw-select-option">
                        <div className="lw-select-box" style={{ height: graphicStore?.context?.layerContext?.currentLayerLineWidth * 10 || 1 }}></div>
                        <div className="lw-select-name">ByLayer</div>
                    </div>
                </Option>
                {/* <Option value="ByBlock" key="ByBlock">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: graphicStore?.context?.layerContext?.currentLayerLineWidth * 10 || 1 }}></div>
                            <div className="lw-select-name">ByBlock</div>
                        </div>
                    </Option> */}
                {/* <Option value="Default" key="Default">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: 0.25 * 10 || 1 }}></div>
                            <div className="lw-select-name">默认</div>
                        </div>
                    </Option> */}
                {lineWidthList.map((item, key) => (
                    <Option value={item} key={key}>
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: item * 10 || 1 }}></div>
                            <div className="lw-select-name">{item.toFixed(2) + '毫米'}</div>
                        </div>
                    </Option>
                ))}
            </Select>
        </div>
    )
}
