import { graphicAxios } from "@/config/axios"
import { AxiosResponse } from "axios"

export function saveGraphicProject(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/graphic-projects`, data, {
        params: {
            ...params,
        }
    })
}

export function findGraphicProjects(params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphic-projects`, {
        params: {
            ...params,
        }
    })
}

export function findGraphicProjectsByProjectId(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/projects/${projectId}/graphic-projects`, {
        params: {
            ...params,
        }
    })
}

export function findGraphicProjectByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphic-projects/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function deleteGraphicProjectByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.delete(`/graphic-projects/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function copyGraphicProject(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/graphic-projects/copy`, data, {
        params: {
            ...params,
        }
    })
}