import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import SelectModal from './SelectModal';
import Icon from '@ant-design/icons'
// import SelectSvg from '@/icons/select.svg';
import { ReactComponent as SelectSvg } from "@/commons/icons/select/select.svg";
import { graphicStore } from '@/commons/store/graphic-store';
import { DragModal } from 'tncet-common';
import { EXTRA_SINGAL } from '@/tool/enums/extra-singal';

export default function QuickSelect() {
    const [visible, setVisiable] = useState<boolean>(false)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            if (!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onQuickSelectAwake)) {
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onQuickSelectAwake)
            }
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onQuickSelectAwake).add(awakeQuickSelect);
        } else {
            setTimeout(() => {
                graphicStore?.extraContext.listeners.getSignal(EXTRA_SINGAL.onQuickSelectAwake).add(awakeQuickSelect);
            }, 5000)
        }
        return (() => {
            graphicStore?.extraContext.listeners.getSignal(EXTRA_SINGAL.onQuickSelectAwake).remove(awakeQuickSelect);
        })
    }, [])

    const awakeQuickSelect = () => {
        setVisiable(true)
    }

    const onQuickSelectConfirm = () => {
        setVisiable(false);
    }

    return (
        <div>
            <Tooltip title="快速选择" color="#555555">
                <Button type="link" icon={<Icon style={{ fontSize: '14px' }} component={SelectSvg} />} onClick={() => {
                    graphicStore?.extraContext?.listeners.signals.onOpeCommandActive.dispatch('QSELECT')
                }}></Button>
            </Tooltip>
            <DragModal
                visible={visible}
                destroyOnClose
                onClose={() => setVisiable(false)}
                width={400}
                title='快速选择'>
                <SelectModal
                    onConfirm={() => { onQuickSelectConfirm() }}
                    onCancel={() => { setVisiable(false) }}
                />
            </DragModal>
        </div>
    )
}