/**
 * 本文件存储了额外在外边注册的二级控制器的名称
 */

import { AddBlockControl } from "../engine/subcontrols/AddBlockControl";
import { AwakeCaptureModalControl } from "../engine/subcontrols/AwakeCaptureModalControl";
import { AwakeLayerModalControl } from "../engine/subcontrols/AwakeLayerModalControl";
import { AwakeSelectModalControl } from "../engine/subcontrols/AwakeSelectModalControl";
import { AwakeTextStyleModalControl } from "../engine/subcontrols/AwakeTextStyleModalControl";
import { InsertBlockControl } from "../engine/subcontrols/InsertBlockControl";
import { PropertiesControl } from "../engine/subcontrols/PropertiesControl";


export const EXTRA_SUB_CONTROL = {
    INSERT: { name: 'INSERT', cmd: 'InsertBlockControl', clazz: InsertBlockControl },
    BLOCK: { name: 'BLOCK', cmd: 'AddBlockControl', clazz: AddBlockControl },
    PROPERTIES: { name: 'PROPERTIES', cmd: 'PropertiesControl', clazz: PropertiesControl },
    OSNAP: { name: 'OSNAP', cmd: 'AwakeCaptureModalControl', clazz: AwakeCaptureModalControl },
    LAYER: { name: 'LAYER', cmd: 'AwakeLayerModalControl', clazz: AwakeLayerModalControl },
    QSELECT: { name: 'QSELECT', cmd: 'AwakeSelectModalControl', clazz: AwakeSelectModalControl },
    TEXTSTYLE: { name: 'TEXTSTYLE', cmd: 'AwakeTextStyleModalControl', clazz: AwakeTextStyleModalControl },
    TS: { name: 'TS', cmd: 'AwakeTextStyleModalControl', clazz: AwakeTextStyleModalControl, alias: "TEXTSTYLE" },
}