import React, { useEffect } from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import { UploadFileState, DownloadState, useUploadService, useDownloadService, UploadPanel, DownloadPanel } from 'tncet-common';
import { SideStatus, useSideService } from './commons/context/side-store';
import { SystemStatus, useSystemService } from './commons/context/system-store';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import { checkFileExists, getFileUploadUrl } from './api/geometry/file';
import zhCN from 'antd/lib/locale/zh_CN'
import localStore, { USER_UUID } from './commons/store/local-store';
import { User } from './commons/interface/user';
import { userCheckLogin } from './api/spec-common/user';
import { NavigationStatus, useNavigationService } from './commons/context/navigation-context';
import Login from './panel/login/login';
import GlobalLayout from './layout/global';
import GraphicLayout from './layout/graphic';

export const SystemContext = React.createContext<SystemStatus>(null);
export const UploadFileContext = React.createContext<UploadFileState>(null);
export const DownloadContext = React.createContext<DownloadState>(null);
export const SideContext = React.createContext<SideStatus>(null);
export const NavigationContext = React.createContext<NavigationStatus>(null);

function App() {

    const systemContext = useSystemService();
    const navigate = useNavigate();
    const navigationContext = useNavigationService();

    let timer$;
    useEffect(() => {
        // checkout login
        checkLogin();
        timer$ = setInterval(() => {
            checkLogin();
        }, 80000)
        return (() => {
            clearInterval(timer$);
        })
    }, [])

    const checkLogin = () => {
        userCheckLogin()
            .then(res => {
                let userDetail: User = res.data;
                localStore.set(USER_UUID, userDetail.id);
                localStore.userId = userDetail.uuid;
                navigationContext.setIsDemoUser(userDetail.type === 3);
            }).catch(err => {
                localStore.clear();
                navigate('/login')
            })
    }

    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                components: {
                    Radio: {
                        dotSize: 10,
                        radioSize: 14,
                        buttonPaddingInline: 8,
                        wrapperMarginInlineEnd: 4,
                    },
                },
            }}
        >
            <SystemContext.Provider value={systemContext}>
                <NavigationContext.Provider value={navigationContext}>
                    <UploadFileContext.Provider value={useUploadService()}>
                        <DownloadContext.Provider value={useDownloadService()}>
                            <SideContext.Provider value={useSideService()}>
                                {/* <ProjectMain /> */}
                                <div className="tn-base">
                                    <Routes>
                                        {/* <Route path='/admin/*' element={<BackManageLayout />} /> */}
                                        <Route path='/login/*' element={<Login />} />
                                        {/* <Route path='/login/forget/*' element={<ForgetPwdPage />} /> */}
                                        <Route path='/desktop/:projectId' element={<GlobalLayout />} />
                                        <Route path='/desktop' element={<GlobalLayout />} />
                                        <Route path='/graphic/:graphicId' element={<GraphicLayout />} />
                                        <Route path='*' element={<Navigate to='/desktop' />} />
                                    </Routes>
                                    <div className='upload-download-panel'>
                                        <UploadPanel checkFileExists={checkFileExists} getUploadUrl={getFileUploadUrl} uploadContext={UploadFileContext} />
                                        <DownloadPanel downloadContext={DownloadContext} />
                                    </div>
                                </div>
                            </SideContext.Provider>
                        </DownloadContext.Provider>
                    </UploadFileContext.Provider>
                </NavigationContext.Provider>
            </SystemContext.Provider>
        </ConfigProvider>
    );
}

export default App;