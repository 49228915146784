import localStore from "@/commons/store/local-store";
import { graphicAxios } from "@/config/axios";
import { AxiosResponse } from "axios";
export function createPrimitive(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/primitives`, data, {
        params: {
            ...params,
            graphicId: localStore.graphicId,
        }
    })
}

export function createPrimitiveMulti(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/primitives/multi`, data, {
        params: {
            ...params,
            graphicId: localStore.graphicId,
        }
    })
}

export function findPrimitivesInGraphic(graphicId: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphics/${graphicId}/primitives`, {
        params: {
            ...params,
        }
    })
}

export function deletePrimitivesInGraphic(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    const graphicId = localStore.graphicId;
    return graphicAxios.post(`/graphics/${graphicId}/primitives/delete`, uuids, {
        params: {
            ...params,
        }
    })
}

export function findOneTypePrimitivesInGraphic(graphicId: string, type: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphics/${graphicId}/primitives/type`, {
        params: {
            type: type,
        }
    } )
}