import SystemManagementArea from "@/commons/components/system-management-area/system-management-area";
import "./index.less";
import StmodeInput from "@/panel/stmode-input";
import SystemSwitch from "@/panel/system-switch";
import { DrawPanel } from "@/panel/draw";
import { DymodeInput } from "pytha-ui";
import { graphicStore } from "@/commons/store/graphic-store";



export default function GraphicLayout() {
    return (
        <div className="graphic-container">
            <SystemManagementArea />
            <div className="main">
                <div className="draw">
                    <DrawPanel />
                    <DymodeInput
                        context={graphicStore.context}
                        extraContext={graphicStore.extraContext}
                        leftOffset={20}
                        topOffset={20}
                    />
                </div>
            </div>
            <div className="bottom">
                <StmodeInput />
                <SystemSwitch />
            </div>
        </div>
    )
}
