import localStore from "@/commons/store/local-store";
import { graphicAxios } from "@/config/axios";
import { AxiosResponse } from "axios"

export function createLayer(data: any, params?: {}): Promise<AxiosResponse<any>> {
    const graphicId = localStore.graphicId;
    return graphicAxios.post(`/graphic/${graphicId}/layers`, data, {
        params: {
            ...params,
        }
    })
}

export function updateLayer(data: any, params?: {}): Promise<AxiosResponse<any>> {
    const graphicId = localStore.graphicId;
    return graphicAxios.put(`/graphic/${graphicId}/layers`, data, {
        params: {
            ...params,
        }
    })
}

export function findLayers(graphicId: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphic/${graphicId}/layers`, {
        params: {
            ...params,
        }
    })
}

export function deleteLayer(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.delete(`/layers/${uuid}`, {
        params: {
            ...params,
        }
    })
}