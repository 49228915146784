import localStore from "@/commons/store/local-store";
import { graphicAxios } from "@/config/axios";
import { AxiosResponse } from "axios"

export function saveFont(data: any, params?: {}): Promise<AxiosResponse<any>> {
    const graphicId = localStore.graphicId;
    return graphicAxios.post(`/graphic/${graphicId}/fonts`, data, {
        params: {
            ...params,
        }
    })
}

export function findFontsInGraphic(graphicId: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphic/${graphicId}/fonts`, {
        params: {
            ...params,
        }
    })
}

export function deleteFont(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.delete(`/fonts/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function findFontTtfs(params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/fonts/ttfs`, {
        params: {
            ...params,
        }
    })
}

export function addFontGlyphs(data: any, fileName: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/graphic/font/glyphs`, data, {
        params: {
            fileName: fileName,
            ...params,
        }
    })
}

export function addFontParams(data: any, fileName: string, params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.post(`/graphic/font/params`, data, {
        params: {
            fileName: fileName,
            ...params,
        }
    })
}

export function findFontGlyphs(params?: {}): Promise<AxiosResponse<any>> {
    return graphicAxios.get(`/graphic/font/glyphs`, {
        params: {
            ...params,
        }
    })
}