import { createBlock } from "@/api/geometry/block";
import { BlockT, Command, ModelBase, TnEngineExtraContext } from "pytha";


export class AddBlockCommand extends Command {

    geo: BlockT;
    entities: ModelBase[];
    extraContext: TnEngineExtraContext;

    constructor(block: BlockT, entities: ModelBase[]) {
        super();
        this.extraContext = TnEngineExtraContext.getInstance();
        this.editor = this.extraContext.getCurrentViewEditor();
        this.geo = block;
        this.entities = entities;
    }

    execute = () => {
        this.editor.addObject(this.geo);
        this.editor.removeObjects(this.entities);
        this.extraContext.listeners.signals.needRender.dispatch('redraw');
        this.extraContext.apiContext.createPrimitive(this.geo.toJson());
        createBlock(this.geo.toJson());
        this.extraContext.apiContext.deletePrimitivesInGraphic(this.entities.map(entity => entity.uuid));
    }

    undo(): void {
        this.editor.removeObject(this.geo);
        this.editor.addObjects(this.entities);
        this.extraContext.listeners.signals.needRender.dispatch('redraw');
        this.extraContext.apiContext.deletePrimitivesInGraphic([this.geo.uuid]);
        this.extraContext.apiContext.deleteBlocksInGraphic([this.geo.uuid]);
        this.extraContext.apiContext.createPrimitiveMulti(this.entities.map((entity: ModelBase) => {
            if (entity.toJson) {
                return entity.toJson();
            }
            return null
        }));
    }
}