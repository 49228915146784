
import { Button, Select, Tooltip } from 'antd';
import LayerManage from './LayerManage';
import { DragModal } from 'tncet-common';
// import LayerSvg from '@/icons/layer/layers.svg';
import { ReactComponent as LayerSvg } from '@/commons/icons/layer/layers.svg';
import { Layer } from '@/commons/interface/layer';
import { BulbFilled, BulbOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import { graphicStore } from '@/commons/store/graphic-store';
import { ModelBase, ToggleEntityLayerCommand, ToggleLayerCommand } from 'pytha';
import "./LayerSelect.less"
import { EXTRA_SINGAL } from '@/tool/enums/extra-singal';

const { Option } = Select;

export default function LayerSelect() {

    const [currentLayerUuid, setCurrentLayerUuid] = useState<string>(null)
    const [hasSelected, setHasSelected] = useState<boolean>(false)
    const [layerModalVisible, setLayerModalVisible] = useState<boolean>(false)

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            graphicStore.extraContext.listeners.signals.onSelectChanged.add(onSelectChanged);
            graphicStore.extraContext.listeners.signals.onLayerChanged.add(onSelectChanged);
            graphicStore.extraContext.listeners.signals.onProcessEscape.add(onProcessEscape);
            if (!graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake)) {
                graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL.onLayerManageAwake)
            }
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake).add(awakeLayerManage);
        } else {
            setTimeout(() => {
                graphicStore.extraContext.listeners.signals.onSelectChanged.add(onSelectChanged);
                graphicStore.extraContext.listeners.signals.onLayerChanged.add(onSelectChanged);
                graphicStore.extraContext.listeners.signals.onProcessEscape.add(onProcessEscape);
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake).add(awakeLayerManage);
            }, 5000)
        }

        return (() => {
            graphicStore.extraContext.listeners.signals.onSelectChanged.remove(onSelectChanged);
            graphicStore.extraContext.listeners.signals.onLayerChanged.remove(onSelectChanged);
            graphicStore.extraContext.listeners.signals.onProcessEscape.remove(onProcessEscape);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake).remove(awakeLayerManage);
        })
    }, [graphicStore?.extraContext?.getCurrentViewEditor()])

    const awakeLayerManage = () => {
        setLayerModalVisible(true)
    }

    const onSelectChanged = (entities: ModelBase[]) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor()
        let selects: ModelBase[] = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            // 没有东西选中
            setHasSelected(false)
            return;
        }
        let layerUuid = null;
        for (let selected of selects) {
            if (layerUuid == null) {
                layerUuid = selected.layerUuid;
            } else if (layerUuid !== selected.layerUuid) {
                layerUuid = null;
                break;
            }
        }

        setHasSelected(true)
        setCurrentLayerUuid(layerUuid)
    }

    const onProcessEscape = () => {
        setHasSelected(false)
    }

    const onChange = (value: string) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor()
        let selects = editor?.selectControl.getSelectedEntityList();
        let newLayer = graphicStore?.context?.layerContext.layers.find(item => item?.uuid === value);
        if (selects?.length === 0) {
            let oldLayer = graphicStore?.context?.layerContext.layers?.find(item => item.uuid === graphicStore?.context?.layerContext?.currentLayerUuid);
            editor.history.execute(new ToggleLayerCommand(oldLayer, newLayer));
        } else {
            editor.history.execute(new ToggleEntityLayerCommand(selects, newLayer));
            setCurrentLayerUuid(newLayer?.uuid || null)
        }
        graphicStore.extraContext.listeners.signals.onLayerChanged.dispatch();
    }

    return (
        <div className="layer-select">
            <Tooltip title="图层管理" color="#555555">
                <Button type="link" icon={<LayerSvg style={{ width: "14px", height: "14px" }} />} onClick={() => {
                    // graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('LAYER')
                    graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onLayerManageAwake).dispatch();
                }}></Button>
            </Tooltip>
            <Select
                style={{ width: '200px' }}
                size="small"
                value={hasSelected ? currentLayerUuid : graphicStore?.context?.layerContext?.currentLayerUuid}
                onChange={(e) => { onChange(e) }}>
                {graphicStore?.context?.layerContext.layers?.map((layer: Layer, key) => (
                    <Option value={layer.uuid} key={key}>
                        {
                            <div className="layer-select-option">
                                <div className="layer-select-option-visible">
                                    {!layer.hidden ? <BulbFilled /> : <BulbOutlined style={{ color: '#94c1d5' }} />}
                                </div>
                                <div className="layer-select-option-lock">
                                    {layer.lock ? <LockOutlined style={{ color: '#DB1919' }} /> : <UnlockOutlined style={{ color: '#0168B3' }} />}
                                </div>
                                <div className="layer-select-option-color" style={{ backgroundColor: layer.color }}></div>
                                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>{layer.name}</div>
                            </div>
                        }
                    </Option>
                ))}
            </Select>

            <DragModal
                visible={layerModalVisible}
                destroyOnClose
                width={700}
                onClose={() => { setLayerModalVisible(false) }}
                title='图层管理'>
                <LayerManage></LayerManage>
            </DragModal>
        </div>
    )
}

// export default inject("layerStore")(observer(LayerSelect));