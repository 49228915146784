import {useContext, useEffect, useRef, useState } from 'react'
import './PropertiesSetting.less';
import { Collapse, Input, Select, Row, Col, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import { DTextT, Layer, ModelBase, ModelBaseType, PropertiesType, SolidT, ToggleEntityLayerCommand, ToggleLayerCommand, UpdateEntityGeoPropertyCommand, UpdateEntityTextPropertyCommand } from 'pytha';
import ColorSetting from './ColorSetting';
import LwSetting from './LwSetting';
import { graphicStore } from '@/commons/store/graphic-store';
import { SystemStatus } from '@/commons/context/system-store';
import { SystemContext } from '@/App';


const { Panel } = Collapse;
const { Option } = Select;

export default function PropertiesSetting() {
    const ref = useRef<any>();
    const [displayType, setDisplayType] = useState<any[]>([])
    const [selectedEntities, setSelectedEntities] = useState<any[]>([])
    const [geoProperties, setGeoProperties] = useState<any[]>([])
    const [textProperties, setTextProperties] = useState<any[]>([])
    const [plineVertex, setPlineVertex] = useState<any[]>([])
    const [selectedVertex, setSelectedVertex] = useState<any[]>([])
    const [hasSelected, setHasSelected] = useState<boolean>(false)
    const [selectedType, setSelectedType] = useState<string>(null)
    const [currentLayerUuid, setCurrentLayerUuid] = useState<string>(null)
    const systemContext = useContext<SystemStatus>(SystemContext);

    useEffect(() => {
        if (graphicStore?.extraContext?.listeners) {
            graphicStore?.extraContext?.listeners.signals.onSelectChanged.add(onSelectChanged);
            graphicStore?.extraContext?.listeners.signals.onColorChanged.add(onPropertyChanged);
            graphicStore?.extraContext?.listeners.signals.onLineWidthChanged.add(onPropertyChanged);
            graphicStore?.extraContext?.listeners.signals.onLayerChanged.add(onLayerChanged);
            graphicStore?.extraContext?.listeners.signals.onClampNeedUpdate.add(onClampNeedUpdate);
            graphicStore?.extraContext?.listeners.signals.onUcsChanged.add(onUcsChanged);
            onSelectChanged(null);
        } else {
            setTimeout(() => {
                graphicStore?.extraContext?.listeners.signals.onSelectChanged.add(onSelectChanged);
                graphicStore?.extraContext?.listeners.signals.onColorChanged.add(onPropertyChanged);
                graphicStore?.extraContext?.listeners.signals.onLineWidthChanged.add(onPropertyChanged);
                graphicStore?.extraContext?.listeners.signals.onLayerChanged.add(onLayerChanged);
                graphicStore?.extraContext?.listeners.signals.onClampNeedUpdate.add(onClampNeedUpdate);
                graphicStore?.extraContext?.listeners.signals.onUcsChanged.add(onUcsChanged);
                onSelectChanged(null);
            }, 5000)
        }

        return () => {
            graphicStore?.extraContext?.listeners.signals.onSelectChanged.remove(onSelectChanged);
            graphicStore?.extraContext?.listeners.signals.onColorChanged.remove(onPropertyChanged);
            graphicStore?.extraContext?.listeners.signals.onLineWidthChanged.remove(onPropertyChanged);
            graphicStore?.extraContext?.listeners.signals.onLayerChanged.remove(onLayerChanged);
            graphicStore?.extraContext?.listeners.signals.onClampNeedUpdate.remove(onClampNeedUpdate);
            graphicStore?.extraContext?.listeners.signals.onUcsChanged.remove(onUcsChanged);
        }
    }, [])

    const onLayerChanged = () => {
        let selects = graphicStore?.extraContext?.getCurrentViewEditor()?.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            setCurrentLayerUuid(null)
            setHasSelected(false)
            return;
        }
        setCurrentLayerUuid(selects[0].layerUuid)
        setHasSelected(true)
    }

    const onUcsChanged = () => {
        getGeoProperties(selectedType, selectedEntities);
    }

    const onClampNeedUpdate = () => {
        getGeoProperties(selectedType, selectedEntities);
    }

    const onPropertyChanged = () => {
        graphicStore?.extraContext?.listeners.signals.onFilterChanged.dispatch(selectedEntities);
    }

    const onSelectChanged = (selected) => {
        let entities = graphicStore?.extraContext?.getCurrentViewEditor()?.selectControl.getSelectedEntityList();
        let display = [];
        if (entities?.length > 0) {
            entities.forEach(e => {
                if ((e as SolidT)._geo) {
                    let index = display.findIndex(display => display.type === 'SolidT');
                    if (index === -1) {
                        display.push({ type: 'SolidT', count: entities.filter(item => (item as SolidT)._geo).length, entities: entities.filter(item => (item as SolidT)._geo) });
                    }
                } else {
                    let index = display.findIndex(display => display.type === e.type);
                    if (index === -1) {
                        display.push({ type: e.type, count: entities.filter(item => item.type === e.type).length, entities: entities.filter(item => item.type === e.type) });
                    }
                }
            })
            if (display.length > 1) {
                display.unshift({ type: '全部', count: entities.length, entities: entities });
            }
            let layerUuid;
            for (let selected of display[0].entities) {
                if (layerUuid == null) {
                    layerUuid = selected.layerUuid;
                } else if (layerUuid !== selected.layerUuid) {
                    layerUuid = null;
                    break;
                }
            }
            setCurrentLayerUuid(layerUuid)
            setHasSelected(true)
        } else {
            setCurrentLayerUuid(null)
            setHasSelected(false)
            display.push({ type: '无选择', count: 0, entities: [] })
        }
        setDisplayType(display)
        setSelectedType(display[0].type)
        setSelectedEntities(display[0].entities)

        getGeoProperties(display[0].type, display[0].entities);
        getTextProperties(display[0].type, display[0].entities);
        graphicStore?.extraContext?.listeners.signals.onFilterChanged.dispatch(display[0].entities);
    }

    const onPropertiesSettingSleep = () => {
        systemContext?.setSelectedPanel("")
    }

    const changeFilter = (e) => {
        let display = displayType.find(d => d.type === e);
        setSelectedType(e)
        setSelectedEntities(display.entities)
        getGeoProperties(e, display.entities);
        getTextProperties(e, display.entities);
        let layerUuid;
        for (let selected of display.entities) {
            if (layerUuid == null) {
                layerUuid = selected.layerUuid;
            } else if (layerUuid !== selected.layerUuid) {
                layerUuid = null;
                break;
            }
        }
        setCurrentLayerUuid(layerUuid)
        graphicStore?.extraContext?.listeners.signals.onFilterChanged.dispatch(display.entities);
    }

    const onChange = (value: string) => {
        let editor = graphicStore?.extraContext?.getCurrentViewEditor();
        let selects = selectedEntities;
        let newLayer = graphicStore?.context?.layerContext.layers.find(item => item.uuid === value);
        if (selects.length === 0) {
            let oldLayer = graphicStore?.context?.layerContext.layers.find(item => item.uuid === graphicStore?.context?.layerContext.currentLayerUuid);
            editor.history.execute(new ToggleLayerCommand(oldLayer, newLayer));
        } else {
            editor.history.execute(new ToggleEntityLayerCommand(selects, newLayer));
            setCurrentLayerUuid(newLayer?.uuid || null)
        }
        graphicStore?.extraContext?.listeners.signals.onLayerChanged.dispatch();
        ref.current?.blur();
    }

    const getTextProperties = (selectedType, selects: ModelBase[]) => {
        if (selectedType === 'DTextT') {
            let textInit = (selects[0] as DTextT).getTextProperties();
            let textProperties = [];
            for (let selected of selects) {
                let s_text = (selected as DTextT).getTextProperties();
                textProperties.push(s_text);
            }
            for (let pr of textInit) {
                for (let textPr of textProperties) {
                    if (pr.value != textPr.find(item => item.key === pr.key).value) {
                        pr.value = '*多种*';
                        continue;
                    }
                }
            }
            setTextProperties(textInit)
        } else {
            setTextProperties([])
        }
    }

    const getGeoProperties = (selectedType, selects) => {
        if (selects.length === 0 || selectedType === '全部') {
            setGeoProperties([])
            setSelectedVertex([])
            setPlineVertex([])

            return;
        }
        if (selectedType === 'PlineT') {
            if (selects.length > 1) {
                let geoInit = selects[0].getGeoProperties().filter((item, index) => index >= selects[0].pointList.length);
                let geoProperties = [];
                for (let selected of selects) {
                    let s_geo = selected.getGeoProperties().filter((item, index) => index >= selected.pointList.length);
                    geoProperties.push(s_geo);
                }
                for (let pr of geoInit) {
                    for (let geoPr of geoProperties) {
                        if (pr.value != geoPr.find(item => item.key === pr.key).value) {
                            pr.value = '*多种*';
                            continue;
                        }
                    }
                }
                setPlineVertex([])
                setSelectedVertex([])
                setGeoProperties(geoInit)
            } else {
                let plineVertex = selects[0].getGeoProperties().filter((item, index) => index < selects[0].pointList.length);
                setPlineVertex(plineVertex)
                setGeoProperties(selects[0].getGeoProperties().filter((item, index) => index >= selects[0].pointList.length))
            }
            return;
        }
        let geoInit = selects[0].getGeoProperties();
        let geoProperties = [];
        for (let selected of selects) {
            let s_geo = selected.getGeoProperties();
            geoProperties.push(s_geo);
        }
        let deleteIndex = [];
        geoInit.forEach((pr, index) => {
            for (let geoPr of geoProperties) {
                if (!geoPr.some(item => item.key === pr.key)) {
                    deleteIndex.push(index);
                    break;
                }
                if (pr.value != geoPr.find(item => item.key === pr.key).value) {
                    pr.value = '*多种*';
                    continue;
                }
            }
        })
        let geo = [];
        geoInit.forEach((pr, index) => {
            if (!deleteIndex.some(item => item == index)) {
                geo.push(pr);
            }
        });
        setGeoProperties(geo)
    }

    const changePropertyValue = (key: string, value: number) => {
        if (value === null) {
            return;
        }
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateEntityGeoPropertyCommand(selectedEntities, key, value));
        getGeoProperties(selectedType, selectedEntities);
    }

    const changeTextPropertyValue = (key: string, value: number) => {
        if (value === null) {
            return;
        }
        graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new UpdateEntityTextPropertyCommand(selectedEntities, key, value));
    }

    const onPressEnter = (e) => {
        if (!e.target.value) return;
        if (+e.target.value < 1 || +e.target.value > plineVertex.length) return;
        setSelectedVertex(plineVertex.find((item, index) => index === e.target.value - 1))
    }

    const onStep = (value) => {
        setSelectedVertex(plineVertex[value - 1])
    }
    return (
        <div className="properties-setting-container">
            <div className="manage-header">
                <h4 className="title">特性</h4>
                <div className="modal-close" onClick={onPropertiesSettingSleep}>
                    <CloseOutlined />
                </div>
            </div>
            <div className="manage-select">
                <Select style={{ width: '70%', height: '100%' }} value={selectedType} onChange={changeFilter} dropdownStyle={{ backgroundColor: '#5c5c5c' }}>
                    {displayType?.map(item => (
                        <Option value={item.type} key={item.type}>
                            {ModelBaseType[item.type] || item.type}
                            {item.count > 1 ? '(' + item.count + ')' : null}
                        </Option>
                    ))}
                </Select>
            </div>
            <Collapse expandIconPosition="right" defaultActiveKey={PropertiesType.map(t => t.type)} >
                {PropertiesType.map(item => (
                    <Panel header={item.name} key={item.type}>
                        {item?.properties ? item.properties?.map(pr => (
                            <Row key={pr.Chinese}>
                                <Col span={10}><div className="display-left">{pr.Chinese}</div></Col>
                                <Col span={14}>
                                    <div className="display-right">
                                        {pr?.Chinese === '颜色' && <ColorSetting />}
                                        {pr?.Chinese === '图层' && (
                                            <Select
                                                dropdownStyle={{ backgroundColor: '#5c5c5c' }}
                                                className="layer-select"
                                                style={{ width: '100%' }}
                                                size="small"
                                                value={hasSelected ? currentLayerUuid : graphicStore?.context?.layerContext.currentLayerUuid}
                                                onChange={onChange}
                                                ref={ref}>
                                                {graphicStore?.context?.layerContext.layers.map((layer: Layer, key) => (
                                                    <Option value={layer.uuid} key={key}>
                                                        {
                                                            <div>
                                                                <div>{layer.name}</div>
                                                            </div>
                                                        }
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                        {pr?.Chinese === '线宽' && <LwSetting />}
                                    </div>
                                </Col>
                            </Row>))
                            :
                            <div>
                                {item?.name === '文字' &&
                                    <div>
                                        {textProperties?.map(item => (
                                            <Row key={item.key}>
                                                <Col span={10}><div className="display-left">{item.title}</div></Col>
                                                <Col span={14}>
                                                    {!!item.modifiable ?
                                                        <Input
                                                            key={item.value}
                                                            className="display-right"
                                                            defaultValue={item.value}
                                                            // onBlur={(e) => changeTextPropertyValue(item.key, +e.target.value)}
                                                            //@ts-ignore
                                                            onPressEnter={(e) => changeTextPropertyValue(item.key, +e.target.value)} />
                                                        :
                                                        <Input readOnly={!item.modifiable} className="display-right" value={item?.value}></Input>
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                }
                                {item?.name === '几何图形' &&
                                    <div>
                                        {selectedVertex?.map(item => (
                                            <Row key={item.key}>
                                                <Col span={10}><div className="display-left">{item.title}</div></Col>
                                                <Col span={14}>
                                                    {!!item.optional ?
                                                        <InputNumber
                                                            min={1}
                                                            max={plineVertex.length}
                                                            value={item.value}
                                                            onStep={onStep}
                                                            onPressEnter={onPressEnter}
                                                            className="display-right"
                                                            style={{ width: '100%' }}
                                                            size="small"
                                                        />
                                                        :
                                                        <Input
                                                            key={item.key}
                                                            className="display-right"
                                                            defaultValue={item.value}
                                                            // onBlur={(e) => changePropertyValue(item.key, +e.target.value)}
                                                            //@ts-ignore
                                                            onPressEnter={(e) => changePropertyValue(item.key, +e.target.value)}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                        {geoProperties?.map(item => (
                                            <Row key={item.key}>
                                                <Col span={10}><div className="display-left">{item.title}</div></Col>
                                                <Col span={14}>
                                                    {!!item.modifiable ?
                                                        <Input
                                                            key={item.value}
                                                            className="display-right"
                                                            defaultValue={item.value}
                                                            // onBlur={(e) => changePropertyValue(item.key, +e.target.value)}
                                                            //@ts-ignore
                                                            onPressEnter={(e) => changePropertyValue(item.key, +e.target.value)} />
                                                        :
                                                        <Input readOnly={!item.modifiable} className="display-right" value={item?.value}></Input>
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                }
                            </div>
                        }
                    </Panel>
                ))}
            </Collapse>
        </div>
    )
}
