/**
 * 本文件存储了额外在外边注册的signal信号的名称
 */

export const EXTRA_SINGAL = {
    // 显示特性面板
    onPropertiesShow: "onPropertiesShow",

    // 命令唤醒图层管理窗信号
    onLayerManageAwake: "onLayerManageAwake",
    // 命令唤醒捕捉设置窗信号
    onCaptureSettingAwake: "onCaptureSettingAwake",
    // 命令唤醒快速选择弹窗信号
    onQuickSelectAwake: "onQuickSelectAwake",
    // 激活新建文字样式拖拽窗口
    activeNewTextStyle: "activeNewTextStyle",

    // 激活文字样式窗口
    onTextStyleManageAwake: "onTextStyleManageAwake",    
    // 唤醒块定义弹窗
    onBlockDefineAwake: "onBlockDefineAwake",    
    // 块定义指定点
    onBlockDefineSelectPoint:"onBlockDefineSelectPoint",    
    // 块定义返回点
    onBlockDefinePoint: "onBlockDefinePoint",    
    // 块定义选择对象
    onBlockDefineSelectEntity: "onBlockDefineSelectEntity",   
    // 创建块
    onBlockDefine: "onBlockDefine",     
    // 取消创建块
    onBlockDefineOff: "onBlockDefineOff",     
    // 唤醒块插入弹窗
    onBlockInsertAwake: "onBlockInsertAwake",    
    // 块插入指定点
    onBlockInsertSelectPoint: "onBlockInsertSelectPoint",    
    // 块插入返回点
    onBlockInsertPoint: "onBlockInsertPoint",    
    // 插入块
    onBlockInsert: "onBlockInsert",  
    // 取消插入块
    onBlockInsertOff: "onBlockInsertOff",    
}