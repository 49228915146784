import './system-management-area.less'
import { ReactComponent as BIMSvg } from '@/commons/icons/top/bim.svg';
import { ReactComponent as BlackDownSvg } from '@/commons/icons/top/black-arrow.svg';
import { useNavigate } from 'react-router-dom';
import LayerSelect from '../layer/LayerSelect';
import ColorChange from '../color/ColorChange';
import LwSelect from '../line-width/LwSelect';
import UcsSelect from '../ucs/UcsSelect';
import TextStyleSelect from '../text-style/TextStyleSelect';
import QuickSelect from '../qselect/QuickSelect';
import BlockDefine from '../block/BlockDefine';
import BlockInsert from '../block/BlockInsert';
export default function SystemManagementArea() {
    const navigate = useNavigate();
    return (
        <div className="sys-man-top-nav-container">
            <div className='top-logo'>
                <BIMSvg onClick={() => { navigate("/desktop") }} />
                <BlackDownSvg className='arrow-icon' />
            </div>
            <div className="menu-group">
                <LayerSelect />
                <div className="divider"></div>
                <ColorChange />
                <div className="divider"></div>
                <LwSelect />
            </div>
            <div className="menu-group">
                <UcsSelect />
            </div>
            <div className="menu-group">
                <TextStyleSelect />
            </div>
            <div className="menu-group">
                <QuickSelect />
            </div>
            <div className="dragModal-block-define">
                <BlockDefine />
            </div>
            <div className="dragModal-block-insert">
                <BlockInsert />
            </div>
        </div >
    )
}